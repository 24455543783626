import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import jwtDecode from "jwt-decode";

import TotalRoda from "./TotalRoda";

import BarChartKategori from "./Chart/BarChartKategori";
import BarChartPerbulanR2 from "./Chart/BarChartPerbulanR2";
import BarChartPerbulanR4 from "./Chart/BarChartPerbulanR4";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      } else {
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header titleHead="Analytics" icon="solar:chart-2-bold-duotone" />
      <div className="p-4">
        <div className="mt-4">
          <div className="mb-5">
            <TotalRoda />
          </div>
          <div className="flex gap-5">
            <BarChartPerbulanR2 />
            <BarChartPerbulanR4 />
          </div>
          <div className="mb-8 mt-5">
            <BarChartKategori />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
