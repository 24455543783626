import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify-icon/react";
import oct from "../../assets/images/logo-oct-white.png";

const Sidebar = ({ isCollapsed, toggleSidebar }) => {
  const menuList = [
    {
      title: "Navigation",
    },
    {
      title: "Dashboard Reguler",
      path: "/dashboard",
      icon: (
        <Icon
          icon="solar:home-smile-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Analytics",
      path: "/analytics",
      icon: (
        <Icon
          icon="solar:chart-2-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Dashboard Renbut TNKB",
      path: "/dashboard-renbut-tnkb",
      icon: (
        <Icon
          icon="solar:graph-new-up-bold"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Master",
    },
    {
      title: "Polda",
      path: "/polda",
      icon: (
        <Icon
          icon="solar:buildings-2-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Samsat",
      path: "/samsat",
      icon: (
        <Icon
          icon="solar:home-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Bbm Kendaraan",
      path: "/bbm-kendaraan",
      icon: (
        <Icon
          icon="solar:gas-station-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Jenis Kendaraan",
      path: "/jenis-kendaraan",
      icon: (
        <Icon
          icon="solar:bus-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Model Kendaraan",
      path: "/model-kendaraan",
      icon: (
        <Icon
          icon="solar:incognito-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Warna TNKB",
      path: "/warna-tnkb",
      icon: (
        <Icon
          icon="solar:waterdrops-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Keterangan Pendaftaran",
      path: "/keterangan-pendaftaran",
      icon: (
        <Icon
          icon="solar:book-bookmark-minimalistic-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Data Order",
    },
    {
      title: "Order",
      path: "/order",
      icon: (
        <Icon
          icon="solar:clipboard-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Sync Order",
      path: "/sync-order",
      icon: (
        <Icon
          icon="solar:server-square-update-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Manajemen Renbut",
      path: "/renbut",
      icon: (
        <Icon
          icon="solar:graph-up-bold"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Laporan",
    },
    {
      title: "Laporan TNKB",
      path: "/laporan-tnkb",
      icon: (
        <Icon
          icon="solar:graph-new-up-bold"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Settings",
    },
    {
      title: "Generate Token",
      path: "/generate-token",
      icon: (
        <Icon
          icon="solar:refresh-square-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "Reset Total Report",
      path: "/reset-total-report",
      icon: (
        <Icon
          icon="solar:smartphone-update-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "User Workshop",
      path: "/kaworkshop",
      icon: (
        <Icon
          icon="solar:user-check-rounded-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
    {
      title: "User",
      path: "/user",
      icon: (
        <Icon
          icon="solar:users-group-rounded-bold-duotone"
          style={{ fontSize: "24px", color: "#facc15" }}
        />
      ),
    },
  ];

  return (
    <div
      className={`min-h-screen flex flex-col ${
        isCollapsed ? "flex-auto" : "flex-shrink-0"
      } antialiased fixed z-20`}
    >
      <div
        className={`fixed flex flex-col top-0 left-0 ${
          isCollapsed ? "w-16" : "w-64"
        } bg-[#1E3B8B] h-full border-r transition-width duration-300`}
      >
        <div className="flex items-center justify-between gap-2 h-24">
          {isCollapsed ? (
            ""
          ) : (
            <img src={oct} className="w-[50px] ml-2" alt="logo" />
          )}
          <div
            className={`font-sans font-bold text-sm text-white ${
              isCollapsed ? "hidden" : ""
            }`}
          >
            Order Cetak TNKB
          </div>
          <button onClick={toggleSidebar} className="p-2">
            {isCollapsed ? (
              <Icon
                icon="solar:square-double-alt-arrow-right-bold"
                style={{ fontSize: "24px", color: "#facc15" }}
              />
            ) : (
              <Icon
                icon="solar:square-double-alt-arrow-left-bold"
                style={{ fontSize: "24px", color: "#facc15" }}
              />
            )}
          </button>
        </div>
        <div className="overflow-y-auto flex-grow">
          <ul className="flex flex-col py-4 space-y-1">
            {menuList.map((menu, index) => {
              if (menu.path) {
                return (
                  <li key={`menu-${index}`}>
                    <NavLink
                      to={menu.path}
                      className={({ isActive }) =>
                        isActive
                          ? "relative flex flex-row items-center ml-1 mr-1 h-11 focus:outline-none text-black bg-white pr-6 rounded-lg"
                          : "relative flex flex-row items-center ml-1 mr-1 h-11 focus:outline-none text-white hover:bg-gray-100 hover:text-gray-800 transition ease-in-out delay-75 duration-300 border-transparent rounded-lg"
                      }
                      title={menu.title}
                    >
                      <span className="inline-flex justify-center items-center ml-4">
                        {menu.icon}
                      </span>
                      <span
                        className={`ml-2 text-sm tracking-wide truncate ${
                          isCollapsed ? "hidden" : ""
                        }`}
                      >
                        {menu.title}
                      </span>
                    </NavLink>
                  </li>
                );
              } else {
                return (
                  <li key={`list-${index}`} className="px-5">
                    <div className="flex flex-row items-center h-8">
                      <div
                        className={`text-sm font-bold tracking-wide text-white ${
                          isCollapsed ? "hidden" : ""
                        }`}
                      >
                        {menu.title}
                      </div>
                    </div>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
