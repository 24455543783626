import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL_MASTER } from "../../config/index";
import { Icon } from "@iconify-icon/react";
import ErrorAccess from "../ErrorPage/ErrorAccess";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestDelete, sendRequestGet } from "../../swr";
import Loading from "../Loading";
import TableGrid from "../../components/TableGrid";
import SelectFilter from "../../components/Form2/SelectFilter";
import Modal from "../../components/Modal";

const Table = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [field, setField] = useState("namaPolda");
  const [fieldQuery, setFieldQuery] = useState("namaPolda");
  const [keyword, setKeyword] = useState("");
  const [keywordQuery, setKeywordQuery] = useState("");

  const [dataFilter, setDataFilter] = useState([]);
  const [urlGen, setUrlGen] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const itemTahun = [
    {
      name: "2024",
      value: "2024",
    },
    {
      name: "2023",
      value: "2023",
    },
    {
      name: "2022",
      value: "2022",
    },
  ];

  const _handleGetValue = ({ name }) => {
    const arrayData = [...dataFilter];
    const find = arrayData.find((ft) => {
      return ft.name === name;
    });

    if (find) {
      return find.value;
    } else {
      return "";
    }
  };

  const _handleFilterSet = ({ name, val, valRename }) => {
    if (name !== "" && val !== "") {
      const arrayData = [...dataFilter];
      const find = arrayData.find((ft) => {
        return ft.name === name;
      });

      if (find) {
        find.name = name;
        find.value = val;
        find.valRename = valRename;
        setDataFilter(arrayData);
      } else {
        setDataFilter([
          ...dataFilter,
          { name: name, value: val, valRename: valRename },
        ]);
      }
    } else {
      _handleCloseFilter({ name: name });
    }
  };

  const _handleValRename = ({ dataRename, valRename }) => {
    const resRename = dataRename.find(
      (item) => String(item.value) === valRename
    );

    if (resRename) {
      return resRename.name;
    } else {
      return "-";
    }
  };

  const _handleCloseFilter = ({ name }) => {
    const arrayData = [...dataFilter];
    const index = arrayData.findIndex((ft) => {
      return ft.name === name;
    });

    if (index > -1) {
      arrayData.splice(index, 1);
      setDataFilter(arrayData);
    }
  };

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_MASTER}/master/renbut`,
    sendRequestDelete
  );

  const { data, error, isLoading, mutate } = useSWR(
    `${API_URL_MASTER}/master/renbut?field=${fieldQuery}&search=${keywordQuery}${
      urlGen !== "" ? `&${urlGen}` : ""
    }&page=${page}&limit=${limit}`,
    sendRequestGet
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    let queryFilter = [];
    if (dataFilter.length > 0) {
      for (const dtf of dataFilter) {
        queryFilter.push(`${dtf.name}=${dtf.value}`);
      }
    }

    setUrlGen(queryFilter.join("&"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter]);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const deleteData = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await trigger({ id: id });

          mutate();

          Swal.fire("Deleted!", result.message, "success");
        } catch (e) {
          Swal.fire("Deleted!", e.info.message, "error");
        }
      }
    });
  };

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  const searchData = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setKeywordQuery(keyword);
    setFieldQuery(field);
  };

  const resetPage = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setKeyword("");
    setKeywordQuery("");
    setField("namaPolda");
    setFieldQuery("namaPolda");
    setLimit(10);
  };

  if (isLoading || isMutating) return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <>
      <TableGrid
        search={{
          itemField: [
            {
              title: "Nama Polda",
              value: "namaPolda",
            },
            {
              title: "Kode Polda",
              value: "poldaKode",
            },
          ],
          setField: setField,
          field: field,
          keyword: keyword,
          setKeyword: setKeyword,
          submit: searchData,
        }}
        toolbox={{
          reset: resetPage,
          add: "add",
        }}
        modal={{
          setOpenModal: setOpenModal,
          openModal: openModal,
          dataFilter: dataFilter,
          closeFilter: _handleCloseFilter,
        }}
        columns={[
          "#",
          "KODE POLDA",
          "NAMA POLDA",
          "TAHUN",
          "TOTAL R2",
          "TOTAL R4",
          "CREATED AT",
          "UPDATED AT",
          "EDIT",
          "HAPUS",
        ]}
        data={data.data.map((val) => {
          return [
            val.idRenbut,
            val.poldaKode,
            val.namaPolda,
            val.tahun,
            val.totalR2,
            val.totalR4,
            val.createdAt,
            val.updatedAt,
            <Link to={`edit/${val.idRenbut}`}>
              <Icon
                icon="solar:pen-new-round-bold-duotone"
                style={{ fontSize: "21px", color: "#65a30d" }}
              />
            </Link>,
            <button type="button" onClick={() => deleteData(val.idRenbut)}>
              <Icon
                icon="solar:trash-bin-trash-bold-duotone"
                style={{ fontSize: "21px", color: "#e11d48" }}
              />
            </button>,
          ];
        })}
        totalDocs={data.totalRows}
        totalPages={data.totalPage}
        page={page}
        limit={limit}
        setLimit={setLimit}
        changePage={changePage}
      />

      <Modal
        title="Filter Renbut"
        active={openModal}
        setClose={setOpenModal}
        content={
          <>
            <h2 className="font-sans font-bold text-sm mb-3 border-b px-1 pb-1 mt-4">
              FILTER TAHUN
            </h2>
            <div className="mt-1 grid grid-cols-6 gap-x-4 gap-y-3 px-4">
              <div className="sm:col-start-1 sm:col-span-6">
                <SelectFilter
                  label="Tahun"
                  name="tahun"
                  nameFilter="tahun"
                  set={_handleFilterSet}
                  fRename={_handleValRename}
                  val={_handleGetValue({ name: "tahun" })}
                  item={itemTahun}
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default Table;
