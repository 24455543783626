/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { sendRequestGet } from "../../swr";
import { API_URL_MASTER } from "../../config";
import { Icon } from "@iconify-icon/react";
import useSWR from "swr";
import Loading from "../Loading";
import Select2 from "../../components/Form2/Select2";
import PersenRoda from "./PersenRoda";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../styles.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const Table = () => {
  const navigate = useNavigate();

  const [thn, setThn] = useState(new Date().getFullYear());
  const [dataThn, setDataThn] = useState([]);

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const { data, isLoading } = useSWR(
    `${API_URL_MASTER}/master-report-renbut/polda?tahun=${thn}`,
    sendRequestGet,
    {
      errorRetryInterval: 5000,
    }
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    loadTahun();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (data) {
      setRowData(data.data);
      setColumnDefs([
        {
          field: "namaPolda",
          headerName: "DITLANTAS",
          pinned: "left",
          cellClass: "bold-header",
          headerClass: "centered-header bold-header bg-[#f3f4f6]",
        },
        {
          headerName: "RENBUT JASA EMBOSSING TNKB",
          marryChildren: true,
          headerClass: "centered-header bold-header bg-[#dcfce7]",
          children: [
            {
              field: "R2/R3",
              colId: "R2/R3",
              headerClass: "centered-header bold-header bg-[#dcfce7]",
              valueGetter: (params) => {
                return new Intl.NumberFormat("id-ID").format(
                  params.data.totRenbutR2 || 0
                );
              },
            },
            {
              field: "R4/LEBIH",
              colId: "R4/LEBIH",
              headerClass: "centered-header bold-header bg-[#dcfce7]",
              valueGetter: (params) => {
                return new Intl.NumberFormat("id-ID").format(
                  params.data.totRenbutR4 || 0
                );
              },
            },
          ],
        },
        {
          headerName: "EMBOSSING TNKB",
          marryChildren: true,
          headerClass: "centered-header bold-header bg-[#fef9c3]",
          children: [
            {
              field: "R2/R3",
              colId: "R2/R3",
              headerClass: "centered-header bold-header bg-[#fef9c3]",
              valueGetter: (params) => {
                return new Intl.NumberFormat("id-ID").format(
                  params.data.totR2Konfirm || 0
                );
              },
            },
            {
              field: "R4/LEBIH",
              colId: "R4/LEBIH",
              headerClass: "centered-header bold-header bg-[#fef9c3]",
              valueGetter: (params) => {
                return new Intl.NumberFormat("id-ID").format(
                  params.data.totR4Konfirm || 0
                );
              },
            },
          ],
        },
        {
          headerName: "EMBOSSING TNKB (HUTANG)",
          marryChildren: true,
          headerClass: "centered-header bold-header bg-[#ecfccb]",
          children: [
            {
              field: "R2/R3",
              colId: "R2/R3",
              headerClass: "centered-header bold-header bg-[#ecfccb]",
              valueGetter: (params) => {
                return new Intl.NumberFormat("id-ID").format(
                  params.data.totR2Hutang || 0
                );
              },
            },
            {
              field: "R4/LEBIH",
              colId: "R4/LEBIH",
              headerClass: "centered-header bold-header bg-[#ecfccb]",
              valueGetter: (params) => {
                return new Intl.NumberFormat("id-ID").format(
                  params.data.totR4Hutang || 0
                );
              },
            },
          ],
        },
        {
          headerName: "SISA RENBUT EMBOSSING TNKB",
          marryChildren: true,
          headerClass: "centered-header bold-header bg-[#e0e7ff]",
          children: [
            {
              field: "R2/R3",
              colId: "R2/R3",
              headerClass: "centered-header bold-header bg-[#e0e7ff]",
              valueGetter: (params) => {
                return new Intl.NumberFormat("id-ID").format(
                  params.data.sisaRenbutR2 || 0
                );
              },
            },
            {
              field: "R4/LEBIH",
              colId: "R4/LEBIH",
              headerClass: "centered-header bold-header bg-[#e0e7ff]",
              valueGetter: (params) => {
                return new Intl.NumberFormat("id-ID").format(
                  params.data.sisaRenbutR4 || 0
                );
              },
            },
          ],
        },
        {
          headerName: "PERSEN PENGGUNAAN MATERIAL",
          marryChildren: true,
          headerClass: "centered-header bold-header bg-[#fce7f3]",
          children: [
            {
              field: "R2/R3",
              colId: "R2/R3",
              headerClass: "centered-header bold-header bg-[#fce7f3]",
              cellRenderer: (params) => {
                const persen = params.data.persenRenbutR2g;
                return (
                  <div>
                    {persen >= 50 ? (
                      <div
                        className="bg-rose-600 text-xs font-medium text-black text-center p-1 leading-none rounded-sm"
                        style={{ width: `${persen}%`, height: "20px" }}
                      >
                        {params.data.persenRenbutR2}
                      </div>
                    ) : (
                      <div
                        className="bg-blue-600 text-xs font-medium text-black text-center p-1 leading-none rounded-sm"
                        style={{ width: `${persen}%`, height: "20px" }}
                      >
                        {params.data.persenRenbutR2}
                      </div>
                    )}
                  </div>
                );
              },
            },
            {
              field: "R4/LEBIH",
              colId: "R4/LEBIH",
              headerClass: "centered-header bold-header bg-[#fce7f3]",
              cellRenderer: (params) => {
                const persen = params.data.persenRenbutR4g;
                return (
                  <div>
                    {persen >= 50 ? (
                      <div
                        className="bg-rose-600 text-xs font-medium text-black text-center p-1 leading-none rounded-sm"
                        style={{ width: `${persen}%`, height: "20px" }}
                      >
                        {params.data.persenRenbutR4}
                      </div>
                    ) : (
                      <div
                        className="bg-blue-600 text-xs font-medium text-black text-center p-1 leading-none rounded-sm"
                        style={{ width: `${persen}%`, height: "20px" }}
                      >
                        {params.data.persenRenbutR4}
                      </div>
                    )}
                  </div>
                );
              },
            },
          ],
        },
        {
          headerName: "NILAI PNBP TNKB",
          marryChildren: true,
          headerClass: "centered-header bold-header bg-[#ede9fe]",
          children: [
            {
              field: "R2/R3",
              colId: "R2/R3",
              headerClass: "centered-header bold-header bg-[#ede9fe]",
              valueGetter: (params) => {
                return new Intl.NumberFormat("id-ID").format(
                  params.data.pnbpR2 || 0
                );
              },
            },
            {
              field: "R4/LEBIH",
              colId: "R4/LEBIH",
              headerClass: "centered-header bold-header bg-[#ede9fe]",
              valueGetter: (params) => {
                return new Intl.NumberFormat("id-ID").format(
                  params.data.pnbpR4 || 0
                );
              },
            },
            {
              field: "TOTAL",
              colId: "TOTAL",
              headerClass: "centered-header bold-header bg-[#ede9fe]",
              valueGetter: (params) => {
                return new Intl.NumberFormat("id-ID").format(
                  params.data.jmlPnbp || 0
                );
              },
            },
          ],
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, thn]);

  const loadTahun = () => {
    const dataTh = [];
    for (let index = new Date().getFullYear(); index > 2019; index--) {
      dataTh.push({
        name: `${index}`,
        value: index,
      });
    }

    setDataThn(dataTh);
  };

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const searchData = (e) => {
    e.preventDefault();
  };

  const totalPersenRenbut = data ? data.totalPersenRenbut : 0;

  if (isLoading) return <Loading />;

  return (
    <>
      <div className="px-4 mt-10">
        <div className="flex gap-2 justify-between">
          <div className="flex flex-col gap-2">
            <div className="font-bold text-lg text-black">
              DASHBOARD RENBUT TNKB
            </div>

            <div>
              <p className="text-red-500">{data.lastUpdate}</p>
            </div>
          </div>

          <div className="mb-2">
            <div className="w-60">
              <form onSubmit={searchData}>
                <Select2
                  label="Tahun"
                  name="thn"
                  val={thn}
                  set={setThn}
                  item={dataThn}
                />
              </form>
            </div>
          </div>
        </div>

        <div className="flex gap-2 flex-wrap text-white">
          <div className="flex flex-col bg-gradient-to-r from-orange-400 to-orange-600 shadow rounded-lg p-4 flex-grow">
            <span className="font-poppins-regular text-lg font-semibold">
              Total Order (ERI)
            </span>

            <div className="flex items-center justify-between pt-5">
              <span className="text-3xl font-bold">
                {new Intl.NumberFormat("id-ID").format(data.total)}
              </span>
              <div className="h-16">
                <Icon
                  icon="solar:chart-2-bold-duotone"
                  style={{ fontSize: "50px", color: "#d1d5db" }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-gradient-to-r from-green-400 to-green-600 shadow rounded-lg p-4 flex-grow">
            <span className="font-poppins-regular text-lg font-semibold">
              Total Konfirmasi (OCT)
            </span>

            <div className="flex items-center justify-between pt-5">
              <span className="text-3xl font-bold">
                {new Intl.NumberFormat("id-ID").format(data.totalKonfirm)}
              </span>
              <div className="h-16">
                <Icon
                  icon="solar:chart-bold-duotone"
                  style={{ fontSize: "50px", color: "#d1d5db" }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-gradient-to-r from-rose-400 to-rose-600 shadow rounded-lg p-4 flex-grow">
            <span className="font-poppins-regular text-lg font-semibold">
              Selisih
            </span>

            <div className="flex items-center justify-between pt-5">
              <span className="text-3xl font-bold">
                {new Intl.NumberFormat("id-ID").format(data.totalSelisih)}
              </span>
              <div className="h-16">
                <Icon
                  icon="solar:align-bottom-bold-duotone"
                  style={{ fontSize: "50px", color: "#d1d5db" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-2 p-4 text-white">
        <div className="flex-1 bg-gradient-to-r from-blue-400 to-blue-600 shadow rounded-lg h-auto flex items-center justify-between">
          <span className="font-poppins-regular text-lg font-semibold text-center border-r p-2">
            Renbut Jasa Embossing TNKB
            <p className="text-sm text-gray-200">
              Embossing TNKB :{" "}
              <span className="font-bold text-2xl">{totalPersenRenbut}%</span>
            </p>
          </span>
          <PersenRoda totalPersenRenbut={100 - totalPersenRenbut} />
        </div>
        <div className="flex-1 bg-gradient-to-r from-blue-400 to-blue-600 shadow rounded-lg h-auto flex items-center justify-between">
          <span className="font-poppins-regular text-lg font-semibold text-center border-r p-2">
            Jumlah PNBP Diterima
          </span>
          <span className="text-2xl font-bold p-2">
            Rp. {new Intl.NumberFormat("id-ID").format(data.totalPnbp)}
          </span>
        </div>
      </div>

      {/* data grid */}
      <div className="ag-theme-alpine" style={{ height: 800 }}>
        <AgGridReact rowData={rowData} columnDefs={columnDefs} />
      </div>
    </>
  );
};

export default Table;
