import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { API_URL_MASTER, API_URL_OFFICE } from "../../config/index";
import ErrorAccess from "../ErrorPage/ErrorAccess";

import useSWR from "swr";
import { sendRequestGet } from "../../swr";
import Loading from "../Loading";
import TableGrid from "../../components/TableGrid";
import Badge from "../../components/Badge";
import BadgeNopol from "../../components/BadgeNopol";
import Modal from "../../components/Modal";
import InputText from "../../components/Form2/InputText";
import SelectFilter from "../../components/Form2/SelectFilter";

const ButtonLoading = () => {
  return (
    <button
      type="button"
      className="inline-flex items-center justify-center w-full text-white bg-[#3b5998]/50 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-full text-sm px-5 py-2.5"
      disabled
    >
      <svg
        aria-hidden="true"
        className="w-5 h-5 mr-2 text-gray-200 animate-spin fill-blue-600"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
      Loading
    </button>
  );
};

const Table = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [field, setField] = useState("nomorOrder");
  const [fieldQuery, setFieldQuery] = useState("nomorOrder");
  const [keyword, setKeyword] = useState("");
  const [keywordQuery, setKeywordQuery] = useState("");

  const [tglMulai, setTglMulai] = useState("");
  const [tglAkhir, setTglAkhir] = useState("");
  const [tglFilter, setTglFilter] = useState({
    startDate: null,
    endDate: null,
  });

  const [tabFilter, setTabFilter] = useState("");

  const [kodeWilPolda, setKodeWilPolda] = useState("");
  const [noPol, setNoPol] = useState("");
  const [kodeWilayah, setKodeWilayah] = useState("");

  const [kodeWilPoldaQuery, setKodeWilPoldaQuery] = useState("");
  const [noPolQuery, setNoPolQuery] = useState("");
  const [kodeWilayahQuery, setKodeWilayahQuery] = useState("");

  const [dataFilter, setDataFilter] = useState([]);
  const [urlGen, setUrlGen] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const itemWarna = [
    {
      name: "(TAK DIISI)",
      value: 0,
    },
    {
      name: "HITAM",
      value: 1,
    },
    {
      name: "MERAH",
      value: 2,
    },
    {
      name: "KUNING",
      value: 3,
    },
    {
      name: "PUTIH",
      value: 4,
    },
    {
      name: "HIJAU",
      value: 5,
    },
  ];

  const itemStatus = [
    {
      name: "WAITING",
      value: 1,
    },
    {
      name: "PENDING",
      value: 2,
    },
    {
      name: "SUCCESS",
      value: 3,
    },
  ];

  const itemJenisKendaraan = [
    {
      name: "R2/3",
      value: "R2",
    },
    {
      name: "R4/LEBIH",
      value: "R4",
    },
  ];

  const material = [
    {
      name: "TERSEDIA",
      value: 1,
    },
    {
      name: "HUTANG",
      value: 2,
    },
    {
      name: "TIDAK DIKONFIRMASI",
      value: 3,
    },
  ];

  const _handleGetValue = ({ name }) => {
    const arrayData = [...dataFilter];
    const find = arrayData.find((ft) => {
      return ft.name === name;
    });

    if (find) {
      return find.value;
    } else {
      return "";
    }
  };

  const _handleFilterSet = ({ name, val, valRename }) => {
    if (name !== "" && val !== "") {
      const arrayData = [...dataFilter];
      const find = arrayData.find((ft) => {
        return ft.name === name;
      });

      if (find) {
        find.name = name;
        find.value = val;
        find.valRename = valRename;
        setDataFilter(arrayData);
      } else {
        setDataFilter([
          ...dataFilter,
          { name: name, value: val, valRename: valRename },
        ]);
      }
    } else {
      _handleCloseFilter({ name: name });
    }
  };

  const _handleValRename = ({ dataRename, valRename }) => {
    const resRename = dataRename.find(
      (item) => String(item.value) === valRename
    );

    if (resRename) {
      return resRename.name;
    } else {
      return "-";
    }
  };

  const _handleCloseFilter = ({ name }) => {
    const arrayData = [...dataFilter];
    const index = arrayData.findIndex((ft) => {
      return ft.name === name;
    });

    if (index > -1) {
      arrayData.splice(index, 1);
      setDataFilter(arrayData);
    }
  };

  const { data, error, isLoading, mutate, isMutating } = useSWR(
    `${API_URL_OFFICE}/order?field=${fieldQuery}&search=${keywordQuery}&kategori=${tabFilter}&kodep=${kodeWilPoldaQuery}&nopol=${noPolQuery}&kobel=${kodeWilayahQuery}${
      urlGen !== "" ? `&${urlGen}` : ""
    }&tglMulai=${tglMulai}&tglAkhir=${tglAkhir}&page=${page}&limit=${limit}`,
    sendRequestGet
  );

  const { data: dataPolda, isLoading: isLoadingPolda } = useSWR(
    `${API_URL_MASTER}/master/polda-select`,
    sendRequestGet,
    {
      onSuccess: () => {
        mutateSamsat(
          `${API_URL_MASTER}/master/samsat-select?poldaKode=${_handleGetValue({
            name: "polda",
          })}`
        );
      },
    }
  );

  // filter jenis daftar
  const { data: dataJenisDaftar, isLoading: isLoadingKeterangan } = useSWR(
    `${API_URL_MASTER}/master/jenis-daftar-select`,
    sendRequestGet
  );

  const {
    data: dataSamsat,
    isLoading: isLoadingSamsat,
    mutate: mutateSamsat,
  } = useSWR(
    `${API_URL_MASTER}/master/samsat-select?poldaKode=${_handleGetValue({
      name: "polda",
    })}`,
    sendRequestGet
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    let queryFilter = [];
    if (dataFilter.length > 0) {
      for (const dtf of dataFilter) {
        queryFilter.push(`${dtf.name}=${dtf.value}`);
      }
    }

    // console.log(queryFilter.join("&"));
    setUrlGen(queryFilter.join("&"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter]);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  const searchData = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setKeywordQuery(keyword);
    setFieldQuery(field);

    if (tglFilter.startDate !== null && tglFilter.endDate !== null) {
      setTglMulai(new Date(tglFilter.startDate).getTime());
      setTglAkhir(new Date(tglFilter.endDate).getTime());
    } else {
      setTglFilter({
        startDate: null,
        endDate: null,
      });
      setTglMulai("");
      setTglAkhir("");
    }
  };

  const searchNopol = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setKeywordQuery("");
    setFieldQuery(field);

    setKodeWilPoldaQuery(kodeWilPolda);
    setNoPolQuery(noPol);
    setKodeWilayahQuery(kodeWilayah);

    if (tglFilter.startDate !== null && tglFilter.endDate !== null) {
      setTglMulai(new Date(tglFilter.startDate).getTime());
      setTglAkhir(new Date(tglFilter.endDate).getTime());
    } else {
      setTglFilter({
        startDate: null,
        endDate: null,
      });
      setTglMulai("");
      setTglAkhir("");
    }
  };

  const resetPage = (e) => {
    e.preventDefault();
    mutate();
    setPage(0);
    setKeyword("");
    setKeywordQuery("");
    setField("nomorOrder");
    setFieldQuery("nomorOrder");
    setLimit(10);

    setKodeWilPoldaQuery("");
    setNoPolQuery("");
    setKodeWilayahQuery("");
    setKodeWilPolda("");
    setNoPol("");
    setKodeWilayah("");

    setTglFilter({
      startDate: null,
      endDate: null,
    });

    setDataFilter("");
  };

  if (isLoading || isLoadingPolda || isLoadingSamsat || isLoadingKeterangan)
    return <Loading />;

  if (error) return <ErrorAccess />;

  return (
    <>
      <TableGrid
        search={{
          itemField: [
            {
              title: "Nomor Order",
              value: "nomorOrder",
            },
            {
              title: "Nama Pengurus",
              value: "namaLengkap",
            },
          ],
          datePicker: {
            setTglFilter: setTglFilter,
            tglFilter: tglFilter,
          },
          setField: setField,
          field: field,
          keyword: keyword,
          setKeyword: setKeyword,
          submit: searchData,
        }}
        tabFilter={{
          itemTab: [
            {
              title: "ALL",
              value: "",
            },
            {
              title: "RANMOR NON BARU",
              value: "UMUM",
            },
            {
              title: "RANMOR BARU",
              value: "KHUSUS",
            },
            {
              title: "NOPIL",
              value: "NOPIL",
            },
            {
              title: "KONTIGENSI",
              value: "ATENSI",
            },
          ],
          setTab: setTabFilter,
          tabActive: tabFilter,
        }}
        toolbox={{
          reset: resetPage,
        }}
        modal={{
          setOpenModal: setOpenModal,
          openModal: openModal,
          dataFilter: dataFilter,
          closeFilter: _handleCloseFilter,
        }}
        columns={[
          "#",
          "NO. ORDER/NAMA",
          "NOPOL/BLN-THN",
          "W/R",
          "KAT. ORDER",
          "STATUS",
          "MATERIAL",
          "KETERANGAN",
          "SAMSAT/POLDA",
          "OPERATOR",
          "CREATED AT",
          "UPDATED AT",
        ]}
        data={data.data.map((val) => {
          return [
            val.nomorOrder,
            <div className="font-sans">
              {val.nomorOrder}
              <br />
              <span className="text-[10px] text-gray-400 font-normal">
                {val.namaLengkap} - {val.tglOrder}
              </span>
            </div>,
            <div className="font-sans font-bold">
              {val.noPol}
              <br />
              <span className="text-[10px] text-gray-400 font-normal">
                {val.blnThn}
              </span>
              <br />
            </div>,
            <div>
              <BadgeNopol
                text={
                  val.kodeTnkb === 0
                    ? "-"
                    : val.kodeTnkb === 1
                    ? "H"
                    : val.kodeTnkb === 2
                    ? "M"
                    : val.kodeTnkb === 3
                    ? "K"
                    : val.kodeTnkb === 4
                    ? "P"
                    : val.kodeTnkb === 5
                    ? "HJ"
                    : ""
                }
                tipe={
                  val.kodeTnkb === 0
                    ? "-"
                    : val.kodeTnkb === 1
                    ? "hitam"
                    : val.kodeTnkb === 2
                    ? "merah"
                    : val.kodeTnkb === 3
                    ? "kuning"
                    : val.kodeTnkb === 4
                    ? "putih"
                    : val.kodeTnkb === 5
                    ? "hijau"
                    : "N"
                }
              />
              <br />
              <Badge
                text={
                  val.roda.trim().toUpperCase() === "R2"
                    ? "R2/R3"
                    : val.roda.trim().toUpperCase() === "R4"
                    ? "R4/L"
                    : ""
                }
                tipe={
                  val.roda.trim().toUpperCase() === "R2"
                    ? "success"
                    : val.roda.trim().toUpperCase() === "R4"
                    ? "danger"
                    : ""
                }
              />
            </div>,
            <Badge
              text={
                val.kategoriOrder.trim().toUpperCase() === "UMUM"
                  ? "RNB"
                  : val.kategoriOrder.trim().toUpperCase() === "KHUSUS"
                  ? "RB"
                  : val.kategoriOrder.trim().toUpperCase() === "NOPIL"
                  ? "NP"
                  : val.kategoriOrder.trim().toUpperCase() === "ATENSI"
                  ? "KTG"
                  : val.kategoriOrder.trim().toUpperCase() === "TCKB"
                  ? "TC"
                  : val.kategoriOrder
              }
              tipe={
                val.kategoriOrder.trim().toUpperCase() === "UMUM"
                  ? "success"
                  : val.kategoriOrder.trim().toUpperCase() === "KHUSUS"
                  ? "yellow"
                  : val.kategoriOrder.trim().toUpperCase() === "NOPIL"
                  ? "warning"
                  : val.kategoriOrder.trim().toUpperCase() === "ATENSI"
                  ? "info"
                  : val.kategoriOrder.trim().toUpperCase() === "TCKB"
                  ? "dark"
                  : ""
              }
            />,
            <div>
              <Badge
                text={
                  val.status === 1
                    ? "WAITING"
                    : val.status === 2
                    ? "PENDING"
                    : val.status === 3
                    ? "SUCCESS"
                    : "-"
                }
                tipe={
                  val.status === 1
                    ? "danger"
                    : val.status === 2
                    ? "yellow"
                    : val.status === 3
                    ? "success"
                    : "slate"
                }
              />
              <br />
              {val.status === 3 && (
                <div className="bg-yellow-100 text-yellow-600 font-sans text-[10px] border border-dashed border-gray-200 p-1">
                  {val.konfirmDate}
                </div>
              )}
            </div>,
            <div>
              <Badge
                text={
                  val.statusMaterial === "TERSEDIA"
                    ? "TERSEDIA"
                    : val.statusMaterial === "HUTANG"
                    ? "HUTANG"
                    : val.statusMaterial === "TIDAK DIKONFIRMASI"
                    ? "TIDAK DIKONFIRMASI"
                    : val.statusMaterial
                }
                tipe={
                  val.statusMaterial === "TERSEDIA"
                    ? "success"
                    : val.statusMaterial === "HUTANG"
                    ? "yellow"
                    : val.statusMaterial === "TIDAK DIKONFIRMASI"
                    ? "danger"
                    : "-"
                }
              />
            </div>,
            val.keterangan,
            <div className="font-sans">
              {val.namaSamsat}
              <br />
              <span className="text-[10px] text-gray-400 font-normal">
                {val.namaPolda}
              </span>
            </div>,
            val.operator,
            val.createdAt,
            val.updatedAt,
          ];
        })}
        totalDocs={data.totalRows}
        totalPages={data.totalPage}
        page={page}
        limit={limit}
        setLimit={setLimit}
        changePage={changePage}
      />

      <Modal
        title="Filter Order"
        active={openModal}
        setClose={setOpenModal}
        content={
          <>
            <h2 className="font-sans font-bold text-sm mb-3 border-b px-1">
              FORM NOPOL
            </h2>
            <form onSubmit={searchNopol}>
              <div className="grid gap-2 grid-cols-8 px-4 border border-dashed border-gray-200 py-2">
                <div className="col-start-1 col-span-2">
                  <InputText
                    label="Kode Depan"
                    name="kodeWilPolda"
                    val={kodeWilPolda}
                    set={(value) => setKodeWilPolda(value.toUpperCase())}
                    placeholder="B"
                  />
                </div>
                <div className="col-span-4">
                  <InputText
                    label="Nopol"
                    name="noPol"
                    val={noPol}
                    set={(value) => {
                      const filteredValue = value.replace(/[^0-9]/g, "");
                      setNoPol(filteredValue);
                    }}
                    placeholder="1234"
                  />
                </div>
                <div className="col-span-2">
                  <InputText
                    label="Kode Wilayah"
                    name="kodeWilayah"
                    val={kodeWilayah}
                    set={(value) => setKodeWilayah(value.toUpperCase())}
                    placeholder="XYZ"
                  />
                </div>
                <div className="col-start-1 col-span-8">
                  {!isMutating ? (
                    <>
                      <button
                        type="submit"
                        className="w-full text-white bg-orange-600 hover:bg-orange-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-sans font-bold rounded-lg text-sm px-5 py-3 text-center"
                      >
                        Cari
                      </button>
                    </>
                  ) : (
                    <ButtonLoading />
                  )}
                </div>
              </div>
            </form>

            <h2 className="font-sans font-bold text-sm mb-3 border-b px-1 mt-4">
              FILTER SATKER
            </h2>
            <div className="mt-1 grid grid-cols-6 gap-x-4 gap-y-3 px-4">
              <div className="sm:col-start-1 sm:col-span-6">
                <SelectFilter
                  label="Polda"
                  name="poldaKode"
                  nameFilter="polda"
                  fRename={_handleValRename}
                  set={_handleFilterSet}
                  val={_handleGetValue({ name: "polda" })}
                  item={dataPolda.data}
                />
              </div>
              <div className="sm:col-start-1 sm:col-span-6">
                <SelectFilter
                  label="Samsat"
                  name="samsatKode"
                  nameFilter="samsat"
                  set={_handleFilterSet}
                  fRename={_handleValRename}
                  val={_handleGetValue({ name: "samsat" })}
                  item={dataSamsat.data}
                />
              </div>
            </div>

            <h2 className="font-sans font-bold text-sm mb-3 border-b px-1 pb-1 mt-4">
              FILTER STATUS
            </h2>
            <div className="mt-1 grid grid-cols-6 gap-x-4 gap-y-3 px-4">
              <div className="sm:col-start-1 sm:col-span-6">
                <SelectFilter
                  label="Status"
                  name="status"
                  nameFilter="status"
                  set={_handleFilterSet}
                  fRename={_handleValRename}
                  val={_handleGetValue({ name: "status" })}
                  item={itemStatus}
                />
              </div>
            </div>

            <h2 className="font-sans font-bold text-sm mb-3 border-b px-1 pb-1 mt-4">
              FILTER MATERIAL
            </h2>
            <div className="mt-1 grid grid-cols-6 gap-x-4 gap-y-3 px-4">
              <div className="sm:col-start-1 sm:col-span-6">
                <SelectFilter
                  label="Material"
                  name="material"
                  nameFilter="material"
                  set={_handleFilterSet}
                  fRename={_handleValRename}
                  val={_handleGetValue({ name: "material" })}
                  item={material}
                />
              </div>
            </div>

            <h2 className="font-sans font-bold text-sm mb-3 border-b px-1 pb-1 mt-4">
              JENIS DAFTAR
            </h2>
            <div className="mt-1 grid grid-cols-6 gap-x-4 gap-y-3 px-4">
              <div className="sm:col-start-1 sm:col-span-6">
                <SelectFilter
                  label="Jenis Daftar"
                  name="jenisPendaftaran"
                  nameFilter="jenisdaftar"
                  fRename={_handleValRename}
                  set={_handleFilterSet}
                  val={_handleGetValue({ name: "jenisdaftar" })}
                  item={dataJenisDaftar.data}
                />
              </div>
            </div>

            <h2 className="font-sans font-bold text-sm mb-3 border-b px-1 pb-1 mt-4">
              FILTER WARNA TNKB
            </h2>
            <div className="mt-1 grid grid-cols-6 gap-x-4 gap-y-3 px-4">
              <div className="sm:col-start-1 sm:col-span-6">
                <SelectFilter
                  label="Warna TNKB"
                  name="kodetnkb"
                  nameFilter="kodetnkb"
                  set={_handleFilterSet}
                  fRename={_handleValRename}
                  val={_handleGetValue({ name: "kodetnkb" })}
                  item={itemWarna}
                />
              </div>
            </div>

            <h2 className="font-sans font-bold text-sm mb-3 border-b px-1 pb-1 mt-4">
              FILTER JENIS KENDARAAN
            </h2>
            <div className="mt-1 grid grid-cols-6 gap-x-4 gap-y-3 px-4">
              <div className="sm:col-start-1 sm:col-span-6">
                <SelectFilter
                  label="Roda"
                  name="kodeJenisKendaraan"
                  nameFilter="roda"
                  set={_handleFilterSet}
                  fRename={_handleValRename}
                  val={_handleGetValue({ name: "roda" })}
                  item={itemJenisKendaraan}
                />
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default Table;
