import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify-icon/react";
import Paging from "../Paging";
import Datepicker from "react-tailwindcss-datepicker";

const TableRow = (rows, idx) => {
  return rows.map((dt, index) => {
    if (index === 0) {
      return (
        <td key={`row-${idx}${index}`} className="px-2 py-2">
          <div className="flex items-center">
            <input
              id="checkbox-all-search"
              type="checkbox"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
            />
            <label htmlFor="checkbox-all-search" className="sr-only">
              checkbox
            </label>
          </div>
        </td>
      );
    } else {
      if (typeof dt === "object") {
        return (
          <td key={`row-${idx}${index}`} className="px-2 py-2 text-gray-900">
            {dt}
          </td>
        );
      } else {
        return (
          <td key={`row-${idx}${index}`} className="px-2 py-2 text-gray-900">
            {dt}
          </td>
        );
      }
    }
  });
};

const TableToolbox = (props) => {
  return (
    <div className="pt-3 pb-3 bg-white rounded-t-2xl border-t border-dashed">
      {props.tabFilter && <FilterTab {...props} />}
      <div className="flex flex-row items-center px-2">
        {props.search && (
          <div className="flex-1 mr-3">
            <form onSubmit={props.search.submit}>
              <div className="flex flex-row items-center gap-2">
                {props.search.datePicker && (
                  <div className="basis-64">
                    <Datepicker
                      inputClassName="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 w-full p-2 font-sans"
                      separator="s/d"
                      popoverDirection="down"
                      primaryColor={"orange"}
                      showShortcuts={true}
                      showFooter={true}
                      value={props.search.datePicker.tglFilter}
                      onChange={(newValue) =>
                        props.search.datePicker.setTglFilter(newValue)
                      }
                    />
                  </div>
                )}
                <div className="flex-1 flex flex-row">
                  <label
                    htmlFor="search-dropdown"
                    className="mb-2 text-sm font-medium text-gray-900 sr-only"
                  >
                    Filter
                  </label>
                  <select
                    id="fieldsearch"
                    className="text-sm font-sans text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:outline-none focus:ring-gray-300 focus:bg-gray-50 focus:border-blue-500"
                    value={props.search.field}
                    onChange={(e) => props.search.setField(e.target.value)}
                  >
                    {props.search.itemField.map((item, index) => {
                      return (
                        <option key={`src-${index}`} value={item.value}>
                          {item.title}
                        </option>
                      );
                    })}
                  </select>

                  <div className="relative w-full">
                    <input
                      type="search"
                      id="search-dropdown"
                      className="block p-[9px] w-full z-20 font-sans text-sm text-gray-900 bg-gray-100 rounded-r-lg border border-gray-300 focus:ring-gray-300 focus:bg-gray-50 focus:border-blue-500"
                      placeholder="Ketik pencarian..."
                      value={props.search.keyword}
                      onChange={(e) => props.search.setKeyword(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="absolute top-0 right-0 py-2 px-4 text-sm font-sans bg-[#15803d] hover:bg-[#15803d]/90 active:bg-green-600 active:text-gray-300 rounded-r-lg border border-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 items-center"
                    >
                      <Icon
                        icon="solar:plain-bold-duotone"
                        style={{ fontSize: "21px", color: "#FFFFFF" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
        {props.modal && (
          <button
            type="button"
            className="text-orange-600 bg-orange-200 hover:bg-orange-100 active:bg-orange-100 active:text-orange-300 focus:ring-4 focus:outline-none focus:ring-[#F9FAFB]/50 rounded-lg text-sm px-5 py-2 font-sans inline-flex items-center mr-2"
            onClick={() => props.modal.setOpenModal(!props.modal.openModal)}
          >
            <Icon
              icon="solar:pip-bold-duotone"
              style={{ fontSize: "21px", color: "#ea580c" }}
            />
            <span className="font-sans text-xs ml-2">Filter Lainnya</span>
          </button>
        )}
        {props.toolbox && (
          <div className="basis-64 text-end">
            {props.toolbox.download && (
              <button
                type="button"
                onClick={() => props.toolbox.download()}
                className="text-white bg-[#e11d48] hover:bg-[#e11d48]/90 active:bg-[#9f1239]/50  focus:outline-none focus:ring-[#9f1239]/50 rounded-lg font-sans px-5 py-2 inline-flex items-center mr-2"
              >
                <Icon
                  icon="solar:file-download-bold-duotone"
                  style={{ fontSize: "21px", color: "#ffffff" }}
                />
              </button>
            )}
            <button
              type="button"
              className="text-black bg-gray-200 hover:bg-gray-300 active:bg-gray-100 active:text-gray-300 focus:ring-4 focus:outline-none focus:ring-[#F9FAFB]/50 rounded-lg text-sm px-5 py-2 font-sans inline-flex items-center mr-2"
              onClick={props.toolbox.reset}
            >
              <Icon
                icon="solar:refresh-square-bold-duotone"
                style={{ fontSize: "21px", color: "#bdbdbd" }}
              />
              <span className="font-sans text-xs ml-2">Reset</span>
            </button>

            {props.toolbox.add && (
              <Link
                to={props.toolbox.add}
                className="text-white bg-[#2563eb] hover:bg-[#2563eb]/90 active:bg-[#2563eb]/50  focus:outline-none focus:ring-[#2563eb]/50 rounded-lg font-sans px-5 py-2 inline-flex items-center mr-2"
              >
                <Icon
                  icon="solar:document-add-bold-duotone"
                  style={{ fontSize: "21px", color: "#fdba74" }}
                />
                <span className="font-sans text-xs ml-2">Tambah</span>
              </Link>
            )}
          </div>
        )}
      </div>
      {props.modal && (
        <>
          {props.modal.dataFilter && (
            <>
              {props.modal.dataFilter.length > 0 && (
                <div className="flex mt-2 mx-2">
                  <div className="flex flex-nowrap items-center gap-1 border border-dashed border-gray-200 px-2 py-1 rounded-lg">
                    <div className="font-sans text-xs font-bold">Filter:</div>
                    <div className="relative overflow-x-auto">
                      <div className="flex flex-nowrap items-center gap-1">
                        {props.modal.dataFilter.map((df, index) => {
                          return (
                            <div
                              key={`df-${df.name}-${index}`}
                              className="flex flex-none items-center gap-1 font-sans text-xs bg-gray-200 px-2 py-1 rounded-lg hover:bg-gray-300"
                            >
                              <span>{df.valRename}</span>
                              <button
                                onClick={() =>
                                  props.modal.closeFilter({ name: df.name })
                                }
                                className="bg-gray-400 rounded-full p-1 hover:drop-shadow-md hover:bg-gray-600"
                              >
                                <svg
                                  className="w-1 h-1 text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 14 14"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                  />
                                </svg>
                                <span className="sr-only">Close modal</span>
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const FilterTab = (props) => {
  return (
    <div className="text-xs font-sans font-bold text-center text-black border-b border-gray-200 mb-4">
      <ul className="flex flex-wrap -mb-px">
        {props.tabFilter.itemTab.map((tab, index) => (
          <li key={`ls-${index}`} className="me-2">
            <button
              onClick={() => props.tabFilter.setTab(tab.value)}
              className={
                props.tabFilter.tabActive === tab.value
                  ? "inline-block px-4 py-2 border-b-2 border-orange-500 text-orange-600 rounded-t-lg"
                  : "inline-block px-4 py-2 border-b-2 border-transparent rounded-t-lg hover:text-orange-600 hover:border-orange-300"
              }
            >
              {tab.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

const TableGrid = (props) => {
  return (
    <>
      <TableToolbox {...props} />
      <div className="relative overflow-x-auto mb-3">
        <table className="w-full font-sans text-xs text-left text-gray-500 border-b">
          <thead className="border-b border-t text-gray-600 bg-gray-100">
            <tr>
              {props.columns.map((header, index) => {
                if (index === 0) {
                  return (
                    <th
                      key={`colhead-${index}`}
                      scope="col"
                      className="px-2 py-3"
                    >
                      <div className="flex items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                        />
                        <label
                          htmlFor="checkbox-all-search"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </th>
                  );
                } else {
                  return (
                    <th
                      key={`colhead-${index}`}
                      scope="col"
                      className="px-2 py-3"
                    >
                      {header}
                    </th>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {props.data && (
              <>
                {props.data.map((val, index) => {
                  return (
                    <tr
                      key={`colrow-${index}`}
                      className="bg-white border-b border-dashed hover:bg-gray-50"
                    >
                      {TableRow(val, index)}
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      </div>
      {props.totalPages > 0 && (
        <Paging
          totalDocs={props.totalDocs}
          totalPages={props.totalPages}
          page={props.page}
          limit={props.limit}
          setLimit={props.setLimit}
          changePage={props.changePage}
        />
      )}
    </>
  );
};

export default TableGrid;
