import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { API_URL_MASTER, API_URL_OFFICE } from "../../config/index";
import { Icon } from "@iconify-icon/react";
import ErrorAccess from "../ErrorPage/ErrorAccess";

import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { sendRequestGet, sendRequestPostWithToken } from "../../swr";
import Loading from "../Loading";
import Datepicker from "react-tailwindcss-datepicker";
import Select from "../../components/Form2/Select";
import Badge from "../../components/Badge";
import TableGridColor from "../../components/TableGridColor";

const Table = () => {
  const navigate = useNavigate();

  const [poldaKode, setPoldaKode] = useState("");
  const [samsatKode, setSamsatKode] = useState("");

  const [statusOrder, setStatusOrder] = useState("");
  const [limit, setLimit] = useState("");
  const [statusSync, setStatusSync] = useState("");
  const [statusMaterial, setStatusMaterial] = useState("");

  const [isLoadingSync, setIsLoadingSync] = useState(false);
  const [tglFilter, setTglFilter] = useState({
    startDate: null,
    endDate: null,
  });

  const [loadingProgress, setLoadingProgress] = useState(0);
  const [syncMessage, setSyncMessage] = useState("");

  const itemStatusOrder = [
    { name: "WAITING", value: 1 },
    { name: "PENDING", value: 2 },
    { name: "SUCCESS", value: 3 },
  ];

  const itemLimit = [
    { name: "1", value: 1 },
    { name: "10", value: 10 },
    { name: "50", value: 50 },
    { name: "100", value: 100 },
    { name: "1000", value: 1000 },
    { name: "10000", value: 10000 },
  ];

  const itemStatusSync = [
    { name: "YA", value: 1 },
    { name: "TIDAK", value: 0 },
    { name: "NOT FOUND", value: 3 },
  ];

  const itemStatusMaterial = [
    { name: "BELUM DIKONFIRMASI", value: "BK" },
    { name: "TERSEDIA", value: "TS" },
    { name: "TIDAK TERSEDIA", value: "TT" },
  ];

  const [dataTabel, setDataTabel] = useState([]);
  const [dataSyncSuccess, setDataSyncSuccess] = useState([]);
  const [errorPesan, setErrorPesan] = useState("");
  const [totalRows, setTotalRows] = useState(0);

  const { trigger, error, isMutating } = useSWRMutation(
    `${API_URL_OFFICE}/sync-list`,
    sendRequestPostWithToken,
    {
      onSuccess: (data) => {
        setDataTabel(data.data);
        setTotalRows(data.totalRows);
      },
      onError: (error) => {
        setErrorPesan(error.message);
      },
    }
  );

  const { data: dataPolda, isLoading: isLoadingPolda } = useSWR(
    `${API_URL_MASTER}/master/polda-select`,
    sendRequestGet
  );

  const { data: dataSamsat } = useSWR(
    `${API_URL_MASTER}/master/samsat-select?poldaKode=${poldaKode}`,
    sendRequestGet
  );

  const { trigger: triggerSyncOrder } = useSWRMutation(
    `${API_URL_OFFICE}/sync-order`,
    sendRequestPostWithToken
    // {
    //   onSuccess: (data) => {
    //     console.log(data.message);
    //     setDataSyncSuccess([
    //       ...dataSyncSuccess,
    //       { nomorOrder: data.nomorOrder },
    //     ]);
    //   },
    //   onError: (error) => {
    //     console.log(error.message);
    //   },
    // }
  );

  useEffect(() => {
    checkToken();

    // console.log(dataSyncSuccess);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  //2. tombol untuk syncron order
  const _handleSyncOrder = async () => {
    const persen = 100;
    const total = dataTabel.length;
    let progress = 0;
    setIsLoadingSync(true);
    if (dataTabel.length > 0) {
      const updateData = [];
      setDataSyncSuccess([]);
      // check update syncron 1 persatu
      for (const dts of dataTabel) {
        // kirimkan proses ke syncront ke _updateSyncOrder({})
        await _updateSyncOrder(dts);
        updateData.push(dts);
        setDataSyncSuccess(updateData);

        dts.statusSync = 1;
        dataTabel.map((item) =>
          item.nomorOrder === dts.nomorOrder ? { ...item, ...dts } : item
        );

        progress = progress + 1;
        setLoadingProgress(Math.ceil((progress / total) * persen));
      }
      setIsLoadingSync(false);
      setSyncMessage("Sinkronisasi berhasil!");
    } else {
      setIsLoadingSync(false);
      setSyncMessage("Tidak ada data untuk disinkronkan.");
    }
  };

  //3. function untuk update
  const _updateSyncOrder = (dts) => {
    return new Promise(async (resolve) => {
      await triggerSyncOrder({
        nomorOrder: dts.nomorOrder,
        statusMaterial: dts.statusMaterial,
        statusKonfirm: dts.statusKonfirm,
        statusShare: dts.statusShare,
        konfirmDate: dts.konfirmDate,
        userId: dts.userId,
        updatedAt: dts.updatedAt,
      });

      resolve(true);
    });
  };

  const dataSync = async (e) => {
    e.preventDefault();

    if (tglFilter.startDate !== null && tglFilter.endDate !== null) {
      try {
        const result = await trigger({
          limit: limit,
          tglMulai: new Date(tglFilter.startDate).getTime(),
          tglAkhir: new Date(tglFilter.endDate).getTime(),
          statusOrder: statusOrder,
          polda: poldaKode,
          samsat: samsatKode,
          statusSync: statusSync,
          statusMaterial: statusMaterial,
        });
        console.log(result);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleOkClick = () => {
    setSyncMessage("");
  };

  if (error) return <ErrorAccess />;

  if (isLoadingPolda || isMutating) return <Loading />;

  return (
    <>
      {errorPesan && (
        <div className="error-message">
          <p>{errorPesan}</p>
        </div>
      )}
      <div className="p-1">
        <form onSubmit={dataSync}>
          <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-12 mb-5 rounded-lg p-6 bg-blue-100">
            {/* Tanggal */}
            <div className="sm:col-span-3">
              <div className="block font-sans text-sm leading-6 text-black">
                Tanggal
              </div>
              <Datepicker
                inputClassName="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 w-full p-2 font-sans"
                separator="s/d"
                primaryColor={"orange"}
                showShortcuts={true}
                showFooter={true}
                value={tglFilter}
                onChange={(newValue) => setTglFilter(newValue)}
                popoverDirection="down"
                required
              />
            </div>

            {/* Status Order */}
            <div className="sm:col-span-3">
              <Select
                label="Status Order"
                name="roda"
                set={setStatusOrder}
                val={statusOrder}
                item={itemStatusOrder}
              />
            </div>

            {/* Polda */}
            <div className="sm:col-span-3">
              <Select
                label="Polda"
                name="poldaKode"
                set={setPoldaKode}
                val={poldaKode}
                item={dataPolda?.data || []}
              />
            </div>

            {/* Samsat */}
            <div className="sm:col-span-3">
              <Select
                label="Samsat"
                name="samsatKode"
                set={setSamsatKode}
                val={samsatKode}
                item={dataSamsat?.data || []}
              />
            </div>

            <div className="sm:col-span-3">
              <Select
                label="Limit"
                name="limit"
                set={setLimit}
                val={limit}
                item={itemLimit}
              />
            </div>

            <div className="sm:col-span-3">
              <Select
                label="Status Sync"
                name="statusSync"
                set={setStatusSync}
                val={statusSync}
                item={itemStatusSync}
              />
            </div>

            <div className="sm:col-span-3">
              <Select
                label="Status Material"
                name="statusMaterial"
                set={setStatusMaterial}
                val={statusMaterial}
                item={itemStatusMaterial}
              />
            </div>

            {/* Submit Button */}
            <div className="sm:col-span-12 flex justify-between items-center mt-7">
              <button
                type="submit"
                className="text-black bg-green-700 hover:bg-green-600 active:bg-green-600 active:text-green-600 focus:ring-4 focus:outline-none focus:ring-[#F9FAFB]/50 rounded-lg text-sm px-5 py-2.5 font-sans inline-flex items-center justify-center h-10 w-40"
              >
                <Icon
                  icon="solar:calendar-search-bold"
                  style={{ fontSize: "21px", color: "#ffffff" }}
                />
                <span className="font-sans text-xs ml-2 text-white">
                  Cari data
                </span>
              </button>

              <button
                type="button"
                onClick={_handleSyncOrder}
                className={`text-black bg-orange-700 hover:bg-orange-600 active:bg-orange-600 active:text-green-600 focus:ring-4 focus:outline-none focus:ring-[#F9FAFB]/50 rounded-lg text-sm px-5 py-2 font-sans inline-flex items-center mr-2 h-10 w-30 ${
                  dataTabel.length === 0 ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={dataTabel.length === 0}
              >
                <Icon
                  icon="solar:restart-square-bold"
                  style={{ fontSize: "21px", color: "#fff" }}
                />
                <span className="font-sans text-xs ml-2 text-white">
                  Sync Now
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>

      {/* Container Tabel dengan Spinner dan Progress Bar */}
      <div className="relative">
        <div>
          <p>Total Docs: {totalRows || 0}</p>{" "}
        </div>

        <TableGridColor
          columns={[
            "#",
            "NO.ORDER",
            "TGL.ORDER",
            "NOPOL",
            "BLN.THN",
            "POLDA",
            "SAMSAT",
            "RODA",
            "STATUS",
            "MATERIAL",
            "STATUS SYNC",
            "PROCESS",
            "CREATED",
            "UPDATED",
          ]}
          setDataSuccess={dataSyncSuccess}
          data={
            dataTabel.length > 0
              ? dataTabel.map((val, index) => [
                  index + 1,
                  val.nomorOrder,
                  val.tglOrder,
                  val.noPol,
                  val.blnThn,
                  val.namaPolda,
                  val.namaSamsat,
                  val.roda,
                  <div>
                    <Badge
                      text="S"
                      tipe={
                        val.statusShare === 1
                          ? "danger"
                          : val.statusShare === 2
                          ? "success"
                          : "slate"
                      }
                    />
                    <Badge
                      text="K"
                      tipe={
                        val.statusKonfirm === 1
                          ? "danger"
                          : val.statusKonfirm === 2
                          ? "success"
                          : "slate"
                      }
                    />
                    <br />
                    {val.status === 3 && (
                      <div className="bg-yellow-100 text-yellow-600 font-sans text-[10px] border border-dashed border-gray-200 p-1">
                        {val.konfirmDate}
                      </div>
                    )}
                  </div>,
                  <div>
                    <Badge
                      text={
                        val.statusMaterial === "TERSEDIA"
                          ? "TERSEDIA"
                          : val.statusMaterial === "HUTANG"
                          ? "HUTANG"
                          : val.statusMaterial === "TIDAK DIKONFIRMASI"
                          ? "TIDAK DIKONFIRMASI"
                          : "-"
                      }
                      tipe={
                        val.statusMaterial === "TERSEDIA"
                          ? "success"
                          : val.statusMaterial === "HUTANG"
                          ? "yellow"
                          : val.statusMaterial === "TIDAK DIKONFIRMASI"
                          ? "danger"
                          : "slate"
                      }
                    />
                  </div>,
                  <Badge
                    text={
                      val.statusSync === 1
                        ? "YA"
                        : val.statusSync === 3
                        ? "NOT FOUND"
                        : "TIDAK"
                    }
                    tipe={
                      val.statusSync === 1
                        ? "success"
                        : val.statusSync === 3
                        ? "slate"
                        : "danger"
                    }
                  />,
                  <div>-</div>,
                  val.createdAt,
                  val.updatedAt,
                ])
              : []
          }
        />

        <div>
          <p>Total Docs: {totalRows || 0}</p>
          <p>Limit: {limit || 0}</p>
        </div>

        {/* Overlay untuk Progress Bar dan Persentase */}
        {isLoadingSync && (
          <div className="absolute inset-0 flex justify-center bg-white bg-opacity-85">
            <div className="flex flex-col items-center w-full">
              <div className="w-60 bg-gray-300 h-4 rounded-full mb-4">
                <div
                  className="bg-blue-700 h-4 rounded-full"
                  style={{
                    width: `${loadingProgress}%`,
                    background: `repeating-linear-gradient(
                      45deg,
                      blue,
                      blue 10px,
                      transparent 10px,
                      transparent 20px
                    )`, // Menambahkan efek garis putus-putus di dalam bar
                  }}
                ></div>
              </div>
              <div className="text-xl font-semibold text-blue-700">
                {loadingProgress}% Syncing...
              </div>
            </div>
          </div>
        )}

        {/* Tampilkan Pesan Sinkronisasi dengan Tombol OK */}
        {syncMessage && (
          <div className="absolute inset-0 flex justify-center bg-white bg-opacity-85">
            <div className="flex flex-col items-center h-32 bg-gray-100 text-gray-600 p-4 rounded-lg shadow-lg text-center max-w-sm w-full mx-4">
              <div className="flex items-center mb-4">
                <div className="loader mr-2"></div> {/* Indikator loading */}
                <div className="text-xl font-semibold">{syncMessage}</div>
              </div>
              <button
                onClick={handleOkClick}
                className="bg-blue-700 text-white px-4 py-2 rounded-lg"
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Table;
