import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import { Icon } from "@iconify-icon/react";
import { sendRequestGet, sendRequestGetDownload } from "../../swr";
import { API_UPLOAD, API_URL_MASTER } from "../../config";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import Loading from "../Loading";
import Swal from "sweetalert2";
// import Modal from "../../components/Modal";

const TableLaporanTnkb = () => {
  const navigate = useNavigate();

  const [tglMulai, setTglMulai] = useState(new Date().getTime());
  const [tglAkhir, setTglAkhir] = useState(new Date().getTime());
  const [tglFilter, setTglFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [openRows, setOpenRows] = useState({});

  // const { data, isLoading } = useSWR(
  //   `${API_URL_MASTER}/master-report/polda?tglMulai=${tglMulai}&tglAkhir=${tglAkhir}`,
  //   sendRequestGet,
  //   {
  //     errorRetryInterval: 5000,
  //   }
  // );

  // const { trigger: triggerDownload } = useSWRMutation(
  //   `${API_URL_MASTER}/master-report/polda-pdf?tglMulai=${tglMulai}&tglAkhir=${tglAkhir}`,
  //   sendRequestGetDownload
  // );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const searchData = (e) => {
    e.preventDefault();

    if (tglFilter.startDate !== null && tglFilter.endDate !== null) {
      setTglMulai(new Date(tglFilter.startDate).getTime());
      setTglAkhir(new Date(tglFilter.endDate).getTime());
    }
  };

  // const _handleDownloadPdf = async () => {
  //   await Swal.fire({
  //     title: "Proses donwload...",
  //     didOpen: async () => {
  //       Swal.showLoading();

  //       const result = await triggerDownload();
  //       const url = window.URL.createObjectURL(result);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "laporan-korlantas.pdf"); // nama file dan extension sesuaikan dengan file yang di download
  //       document.body.appendChild(link);
  //       link.click();

  //       Swal.fire(
  //         "Download Success!",
  //         "Berhasil mendownload laporan pdf",
  //         "success"
  //       );
  //     },
  //   }).then((result) => {
  //     if (result.dismiss === Swal.DismissReason.timer) {
  //       Swal.close();
  //     }
  //   });
  // };

  // Function to toggle rows
  const toggleRow = (index) => {
    setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  // if (isLoading) return <Loading />;

  return (
    <div className="p-8">
      <h2 className="text-2xl mb-6 font-poppins-semibold">Menu Laporan</h2>
      <p className="mb-6 font-poppins-regular">Semua menu Laporan</p>

      {/* Layout Grid */}
      <div className="grid grid-cols-4 gap-4">
        {/* Menu Item 1 */}
        <Link
          to={`laporan-tnkb-workshop`}
          className="bg-blue-600 hover:bg-blue-700 text-white flex flex-col items-center justify-center rounded-lg shadow-md p-4 cursor-pointer"
        >
          <Icon
            icon="solar:inbox-in-bold"
            style={{ fontSize: "50px", color: "#fff" }}
          />
          <p className="mt-2 text-center">Laporan TNKB Workshop</p>
        </Link>
      </div>
    </div>
  );
};

export default TableLaporanTnkb;
