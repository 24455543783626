import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const BbmKendaraan = () => {
  return (
    <div>
      <Header titleHead="Bbm Kendaraan" icon="solar:gas-station-bold-duotone" />
      <Outlet />
    </div>
  );
};

export default BbmKendaraan;
