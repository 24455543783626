export const SwitchHari = (hari) => {
  switch (hari) {
    case 0:
      hari = "Minggu";
      break;
    case 1:
      hari = "Senin";
      break;
    case 2:
      hari = "Selasa";
      break;
    case 3:
      hari = "Rabu";
      break;
    case 4:
      hari = "Kamis";
      break;
    case 5:
      hari = "Jumat";
      break;
    case 6:
      hari = "Sabtu";
      break;
    default:
      hari = "Nothing";
      break;
  }

  return hari;
};

export const SwitchBulan = (bulan) => {
  // eslint-disable-next-line default-case
  switch (bulan) {
    case 0:
      bulan = "Januari";
      break;
    case 1:
      bulan = "Februari";
      break;
    case 2:
      bulan = "Maret";
      break;
    case 3:
      bulan = "April";
      break;
    case 4:
      bulan = "Mei";
      break;
    case 5:
      bulan = "Juni";
      break;
    case 6:
      bulan = "Juli";
      break;
    case 7:
      bulan = "Agustus";
      break;
    case 8:
      bulan = "September";
      break;
    case 9:
      bulan = "Oktober";
      break;
    case 10:
      bulan = "November";
      break;
    case 11:
      bulan = "Desember";
      break;
  }

  return bulan;
};

export const formatDate = (tgl) => {
  let date = new Date(tgl);
  let tahun = date.getFullYear();
  let bulan = date.getMonth();
  let tanggal = date.getDate();
  let jam = date.getHours();
  let menit = date.getMinutes();
  let detik = date.getSeconds();

  const tglFormat = `${tanggal} ${SwitchBulan(
    bulan
  )} ${tahun} ${jam}:${menit}:${detik}`;

  return tglFormat;
};

export const formatDateSingle = (tgl) => {
  let date = new Date(tgl);
  let tahun = date.getFullYear();
  let bulan = date.getMonth();
  let tanggal = date.getDate();

  const tglFormat = `${tanggal} ${SwitchBulan(bulan)} ${tahun}`;

  return tglFormat;
};

export const formatDateFromUnix = (unix) => {
  let date = new Date(unix);

  let tahun = date.getFullYear();
  let bulan = date.getMonth();
  let tanggal = date.getDate();

  const tglFormat = `${tahun}-${bulan < 10 ? `0${bulan}` : bulan}-${
    tanggal < 0 ? `0${tanggal}` : tanggal
  }`;

  return tglFormat;
};
