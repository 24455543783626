import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_URL_AUTH, API_URL_MASTER } from "../../config/index";
import { useNavigate } from "react-router-dom";
import { HiOutlineSave, HiOutlineArrowLeft } from "react-icons/hi";

import InputText from "../../components/Form2/InputText";

import useSWRMutation from "swr/mutation";
import { sendRequestGet, sendRequestPostWithToken } from "../../swr";
import Loading from "../Loading";
import InputPassword from "../../components/Form/InputPassword";
import Select from "../../components/Form2/Select";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import useSWR from "swr";

const FormAdd = () => {
  const [namaUser, setNamaUser] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [samsatKode, setSamsatKode] = useState("");
  const [poldaKode, setPoldaKode] = useState("");
  const [isPassword, setIsPassword] = useState(false);

  const navigate = useNavigate();

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_AUTH}/user-workshop`,
    sendRequestPostWithToken
  );

  const { data: dataPolda, isLoading } = useSWR(
    `${API_URL_MASTER}/master/polda-select`,
    sendRequestGet
  );

  const { data: dataSamsat } = useSWR(
    `${API_URL_MASTER}/master/samsat-select?poldaKode=${poldaKode}`,
    sendRequestGet
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const result = await trigger({
        namaUser: namaUser,
        username: username,
        password: password,
        samsatKode: samsatKode,
        poldaKode: poldaKode,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  const handlePassword = () => {
    if (isPassword) {
      setIsPassword(false);
    } else {
      setIsPassword(true);
    }
  };

  if (isMutating || isLoading) return <Loading />;

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-sans text-lg font-bold text-indigo-700">
          Tambah User Kaworkshop Baru
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Penambahan data user kaworkshop, isi informasi data dibawah ini.
        </p>
      </div>
      <div className="grid grid-cols-6 py-6">
        <form
          onSubmit={simpan}
          className="col-start-2 col-span-4 py-5 shadow-xl rounded-2xl bg-white"
        >
          <div className="border-b border-gray-300 pb-2 px-4">
            <h2 className="font-sans font-bold text-lg">
              Detail User Kaworkshop
            </h2>
            <p className="font-sans font-normal text-sm text-gray-400">
              Nama lengkap, username, password, polda, samsat.
            </p>
          </div>
          <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 px-4 sm:grid-cols-6">
            {/* <div className="sm:col-start-1 sm:col-span-3">
              <InputText
                label="NIK"
                name="namaLengkap"
                val={namaUser}
                set={setNamaUser}
                placeholder="Masukkan NIK di sini"
              />
            </div> */}
            <div className="sm:col-start-1 sm:col-span-3">
              <InputText
                label="Nama Lengkap"
                name="namaLengkap"
                val={namaUser}
                set={(value) => setNamaUser(value.toUpperCase())}
                placeholder="Masukkan nama kaworkshop"
              />
            </div>
            {/* <div className="sm:col-span-3">
              <InputText
                label="No.HP"
                name="namaLengkap"
                val={namaUser}
                set={setNamaUser}
                placeholder="08xx"
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-6">
              <InputText
                label="Alamat"
                name="namaLengkap"
                val={namaUser}
                set={setNamaUser}
                placeholder="Masukkan nama kaworkshop"
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-6">
              <InputText
                label="Foto KTP"
                name="namaLengkap"
                val={namaUser}
                set={setNamaUser}
                placeholder="Masukkan nama kaworkshop"
              />
            </div> */}
            <div className="flex gap-2 sm:col-start-1 sm:col-span-6">
              <div className="sm:col-start-1 sm:row-span-4 w-full">
                <InputText
                  label="Username"
                  name="username"
                  val={username}
                  set={setUsername}
                  placeholder="Masukkan username"
                />
              </div>
              <div className="sm:col-start-1 sm:row-span-4 w-full relative">
                <InputPassword
                  type={isPassword ? "text" : "password"}
                  label="Password"
                  name="password"
                  set={setPassword}
                  val={password}
                />
                <span
                  className="absolute bottom-3 end-3 cursor-pointer"
                  onClick={handlePassword}
                >
                  {isPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                </span>
              </div>
            </div>
            <div className="sm:col-start-1 sm:col-span-6">
              <Select
                label="Polda"
                name="poldaKode"
                set={setPoldaKode}
                val={poldaKode}
                item={dataPolda?.data || []}
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-6">
              <Select
                label="Samsat"
                name="samsatKode"
                set={setSamsatKode}
                val={samsatKode}
                item={dataSamsat?.data || []}
              />
            </div>
          </div>
          <div className="mt-10 flex items-center gap-x-2 px-4 pt-5 border-t">
            <button
              type="button"
              className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              onClick={() => navigate(-1)}
            >
              <span className="mr-2">
                <HiOutlineArrowLeft size={18} />
              </span>
              <span>Cancel</span>
            </button>
            <button
              type="submit"
              disabled={isMutating}
              className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            >
              <span className="mr-2">
                <HiOutlineSave size={18} />
              </span>
              <span>Simpan Kaworkshop</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormAdd;
