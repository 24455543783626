import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const DashboardPoldas = () => {
  return (
    // className="ml-[-191px]
    <div>
      <Header
        titleHead="Dashboard Polda"
        icon="solar:home-smile-bold-duotone"
      />
      <Outlet />
    </div>
  );
};

export default DashboardPoldas;
