import React, { useEffect, useState, useMemo } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { sendRequestGet } from "../../swr";
import { API_URL_MASTER } from "../../config";
import { Icon } from "@iconify-icon/react";
import useSWR from "swr";
import Loading from "../Loading";
import Select2 from "../../components/Form2/Select2";
import PersenRoda from "./PersenRoda";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const Table = () => {
  const navigate = useNavigate();

  const [thn, setThn] = useState(new Date().getFullYear());
  const [dataThn, setDataThn] = useState([]);

  const { data, isLoading, error } = useSWR(
    `${API_URL_MASTER}/master-report-renbut/polda?tahun=${thn}`,
    sendRequestGet,
    {
      errorRetryInterval: 5000,
      fallbackData: [], // fallback data in case of error
    }
  );

  // Pastikan data ada sebelum digunakan
  const totalPersenRenbut =
    data && data[0]?.persenRenbutR2g ? data[0].persenRenbutR2g : 0;

  // Menyiapkan kolom untuk tabel
  const columns = useMemo(
    () => [
      {
        accessorKey: "namaPolda", // menggunakan "namaPolda" dari data
        header: "Polda",
        muiTableHeadCellProps: {
          sx: {
            color: "green",
            borderBottom: "1px solid #000", // Menambahkan border bawah di header kolom
          },
        },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
      },
      {
        // Group header "ORDER ERI"
        header: "ORDER ERI",
        muiTableHeadCellProps: {
          sx: {
            borderLeft: "1px solid #000", // Menambahkan border bawah di grup header
            borderRight: "1px solid #000", // Menambahkan border bawah di grup header
            borderTop: "1px solid #000", // Menambahkan border bawah di grup header
            borderBottom: "1px solid #000", // Menambahkan border bawah di grup header
            backgroundColor: "#f5f5f5", // Membuat grup header terlihat lebih jelas
            fontWeight: "bold", // Menebalkan font untuk header grup
            padding: "8px", // Menambah padding agar lebih rapi
            textAlign: "center", // Menambahkan text align center agar rapi
          },
        },
        columns: [
          {
            accessorKey: "totRenbutR2",
            header: "Total Renbut R2",
            muiTableHeadCellProps: {
              sx: {
                color: "blue",
                borderRight: "1px solid #000", // Menambahkan border bawah di grup header
                borderLeft: "1px solid #000", // Menambahkan border bawah di grup header
                borderBottom: "1px solid #000", // Border bawah untuk kolom
                textAlign: "center", // Menambahkan text align center agar rapi
              },
            },
            Cell: ({ renderedCellValue }) => (
              <span>
                {new Intl.NumberFormat("id-ID").format(renderedCellValue)}
              </span>
            ),
          },
          {
            accessorKey: "totRenbutR4",
            header: "Total Renbut R4",
            muiTableHeadCellProps: {
              sx: {
                color: "blue",
                borderBottom: "1px solid #000", // Border bawah untuk kolom
                textAlign: "center", // Menambahkan text align center agar rapi
              },
            },
            Cell: ({ renderedCellValue }) => (
              <span>
                {new Intl.NumberFormat("id-ID").format(renderedCellValue)}
              </span>
            ),
          },
          {
            accessorKey: "total",
            header: "Total",
            muiTableHeadCellProps: {
              sx: {
                color: "blue",
                borderBottom: "1px solid #000", // Border bawah untuk kolom
                textAlign: "center", // Menambahkan text align center agar rapi
              },
            },
            Cell: ({ row }) => {
              const total = row.original.totRenbutR2 + row.original.totRenbutR4;
              return (
                <span>{new Intl.NumberFormat("id-ID").format(total)}</span>
              );
            },
          },
        ],
      },
      {
        accessorKey: "sisaRenbutR2",
        header: "Sisa Renbut R2",
        muiTableHeadCellProps: {
          sx: {
            color: "orange",
            borderBottom: "1px solid #000", // Border bawah untuk kolom
            textAlign: "center", // Menambahkan text align center agar rapi
          },
        },
        Cell: ({ renderedCellValue }) => (
          <span>
            {new Intl.NumberFormat("id-ID").format(renderedCellValue)}
          </span>
        ),
      },
      {
        accessorKey: "sisaRenbutR4",
        header: "Sisa Renbut R4",
        muiTableHeadCellProps: {
          sx: {
            color: "orange",
            borderBottom: "1px solid #000", // Border bawah untuk kolom
            textAlign: "center", // Menambahkan text align center agar rapi
          },
        },
        Cell: ({ renderedCellValue }) => (
          <span>
            {new Intl.NumberFormat("id-ID").format(renderedCellValue)}
          </span>
        ),
      },
    ],
    []
  );

  // Pastikan data yang dikirim ke tabel sudah sesuai format
  const tableData =
    Array.isArray(data?.data) && data.data.length > 0 ? data.data : [];

  const table = useMaterialReactTable({
    data: tableData,
    columns,
  });

  useEffect(() => {
    checkToken();
    loadTahun();
  }, [thn]);

  const loadTahun = () => {
    const dataTh = [];
    for (let index = new Date().getFullYear(); index > 2019; index--) {
      dataTh.push({
        name: `${index}`,
        value: index,
      });
    }
    setDataThn(dataTh);
  };

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const searchData = (e) => {
    e.preventDefault();
  };

  // Pastikan kita hanya menampilkan data jika sudah ada
  if (isLoading || !data?.data) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="px-4 mt-10">
        <div className="flex gap-2 justify-between">
          <div className="font-bold text-lg text-black mb-2">
            DASHBOARD RENBUT TNKB
          </div>
          <div>
            <p className="text-red-500">{data?.lastUpdate}</p>
          </div>
        </div>

        <div className="flex gap-2 flex-wrap text-white">
          <div className="flex flex-col bg-gradient-to-r from-orange-400 to-orange-600 shadow rounded-lg p-4 flex-grow">
            <span className="font-poppins-regular text-lg font-semibold">
              Total Order (ERI)
            </span>

            <div className="flex items-center justify-between pt-5">
              <span className="text-3xl font-bold">
                {new Intl.NumberFormat("id-ID").format(data?.total)}
              </span>
              <div className="h-16">
                <Icon
                  icon="solar:chart-2-bold-duotone"
                  style={{ fontSize: "50px", color: "#d1d5db" }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-gradient-to-r from-green-400 to-green-600 shadow rounded-lg p-4 flex-grow">
            <span className="font-poppins-regular text-lg font-semibold">
              Total Konfirmasi (OCT)
            </span>

            <div className="flex items-center justify-between pt-5">
              <span className="text-3xl font-bold">
                {new Intl.NumberFormat("id-ID").format(data?.totalKonfirm)}
              </span>
              <div className="h-16">
                <Icon
                  icon="solar:chart-bold-duotone"
                  style={{ fontSize: "50px", color: "#d1d5db" }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col bg-gradient-to-r from-rose-400 to-rose-600 shadow rounded-lg p-4 flex-grow">
            <span className="font-poppins-regular text-lg font-semibold">
              Selisih
            </span>

            <div className="flex items-center justify-between pt-5">
              <span className="text-3xl font-bold">
                {new Intl.NumberFormat("id-ID").format(data?.selisih)}
              </span>
              <div className="h-16">
                <Icon
                  icon="solar:align-bottom-bold-duotone"
                  style={{ fontSize: "50px", color: "#d1d5db" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 p-4">
        <div className="w-60">
          <form onSubmit={searchData}>
            <Select2
              label="Tahun"
              name="thn"
              val={thn}
              set={setThn}
              item={dataThn}
            />
          </form>
        </div>
        <div className="bg-white shadow rounded-lg h-auto flex flex-col">
          <span className="font-poppins-regular text-lg font-semibold text-center border-b p-2">
            Persentase Penggunaan Material
          </span>
          <div className="flex items-center justify-center">
            <PersenRoda totalPersenRenbut={totalPersenRenbut} />
          </div>
        </div>
        <div className="bg-white shadow rounded-lg h-auto flex flex-col">
          <span className="font-poppins-regular text-lg font-semibold text-center border-b p-2">
            Jumlah PNBP Diterima
          </span>
          <div className="flex flex-grow items-center justify-center">
            <span className="text-2xl font-bold p-2">
              Rp. {new Intl.NumberFormat("id-ID").format(data?.jmlPnbp)}
            </span>
          </div>
        </div>
      </div>

      <MaterialReactTable table={table} />
    </>
  );
};

export default Table;
