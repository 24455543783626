import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { sendRequestGet } from "../../../swr";
import { API_URL_OFFICE } from "../../../config";
import useSWR from "swr";
import Select2 from "../../../components/Form2/Select2";

const BarChartKategori = () => {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  const [bln, setBln] = useState(new Date().getMonth() + 1);
  const [blnName, setBlnName] = useState("");
  const [thn, setThn] = useState(new Date().getFullYear());

  const [dataThn, setDataThn] = useState([]);

  // manual item
  const itemBln = [
    {
      name: "JANUARI",
      value: 1,
    },
    {
      name: "FEBRUARI",
      value: 2,
    },
    {
      name: "MARET",
      value: 3,
    },
    {
      name: "APRIL",
      value: 4,
    },
    {
      name: "MEI",
      value: 5,
    },
    {
      name: "JUNI",
      value: 6,
    },
    {
      name: "JULI",
      value: 7,
    },
    {
      name: "AGUSTUS",
      value: 8,
    },
    {
      name: "SEPTEMBER",
      value: 9,
    },
    {
      name: "OKTOBER",
      value: 10,
    },
    {
      name: "NOVEMBER",
      value: 11,
    },
    {
      name: "DESEMBER",
      value: 12,
    },
  ];

  const { data: kategoriData, isLoading: isLoadingBlnKategori } = useSWR(
    `${API_URL_OFFICE}/report/total-kategori-bulan?bulan=${bln}&tahun=${thn}`,
    sendRequestGet
  );

  useEffect(() => {
    if (!isLoadingBlnKategori && kategoriData) {
      // Kategori yang akan ditampilkan di sumbu X
      const kategori = kategoriData.map((item) => item.kategori);

      // Data untuk berbagai fields
      const dataTotal = kategoriData.map((item) => item.total || 0);

      const dataTotalKonfirm = kategoriData.map(
        (item) => item.totalKonfirm || 0
      );

      const dataTotR2 = kategoriData.map((item) => item.totR2 || 0);
      const dataTotR4 = kategoriData.map((item) => item.totR4 || 0);
      const dataR2Konfirm = kategoriData.map((item) => item.totR2Konfirm || 0);
      const dataR4Konfirm = kategoriData.map((item) => item.totR4Konfirm || 0);
      const dataR2Hutang = kategoriData.map((item) => item.totR2Hutang || 0);
      const dataR4Hutang = kategoriData.map((item) => item.totR4Hutang || 0);
      const dataHutang = kategoriData.map((item) => item.totalHutang || 0);

      // kondisi kategori
      const changeKategori = kategori.map((item) => {
        if (item === "UMUM") {
          return "RANMOR NON BARU";
        } else if (item === "KHUSUS") {
          return "RANMOR BARU";
        } else {
          return item;
        }
      });

      // Konfigurasi chart
      setOptions({
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "90%",
            dataLabels: {
              position: "top", // Position data label
            },
          },
        },
        colors: [
          "#FF5733", // Warna untuk 'Total'
          "#33FF57", // Warna untuk 'Total Konfirmasi'
          "#3357FF", // Warna untuk 'Total R2/R3'
          "#FF33A6", // Warna untuk 'Total R4/Lebih'
          "#A633FF", // Warna untuk 'R2/R3 Konfirmasi (OCT)'
          "#FF8333", // Warna untuk 'R4/Lebih Konfirmasi (OCT)'
          "#33FFF3", // Warna untuk 'Total R2 Hutang'
          "#FF3389", // Warna untuk 'Total R4 Hutang'
          "#A6FF33", // Warna untuk 'Total Hutang'
        ],
        xaxis: {
          categories: changeKategori, //diambil dari kondisi changeKategori
          title: {
            text: "Kategori",
          },
        },
        yaxis: [
          {
            title: {
              text: "Total",
            },
          },
          {
            opposite: true,
            title: {
              text: "Total",
            },
          },
        ],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
            colors: ["#000"], // Warna teks untuk visibilitas
          },
          offsetY: -20,
          formatter: function (val) {
            return val;
          },
        },
      });

      // Set series data
      setSeries([
        {
          name: "Total",
          data: dataTotal,
        },
        {
          name: "Total Konfirmasi",
          data: dataTotalKonfirm,
        },
        {
          name: "Total R2/R3",
          data: dataTotR2,
        },
        {
          name: "Total R4/Lebih",
          data: dataTotR4,
        },
        {
          name: "R2/R3 Konfirmasi (OCT)",
          data: dataR2Konfirm,
        },
        {
          name: "R4/Lebih Konfirmasi (OCT)",
          data: dataR4Konfirm,
        },
        {
          name: "Total R2 Hutang",
          data: dataR2Hutang,
        },
        {
          name: "Total R4 Hutang",
          data: dataR4Hutang,
        },
        {
          name: "Total Hutang",
          data: dataHutang,
        },
      ]);
    }

    loadTahun();
  }, [isLoadingBlnKategori, kategoriData, bln, thn, blnName]);

  const loadTahun = () => {
    const dataTh = [];
    for (let index = new Date().getFullYear(); index > 2019; index--) {
      dataTh.push({
        name: `${index}`,
        value: index,
      });
    }

    setDataThn(dataTh);
  };

  const onchangeBln = async (selectValue) => {
    setBln(selectValue);

    const resBulan = itemBln.find((a) => a.value === parseInt(selectValue));

    if (resBulan) {
      setBlnName(resBulan.name);
    }
  };

  return (
    <div className="flex flex-col bg-white shadow rounded-lg p-2 flex-grow">
      <div className="font-poppins-regular text-lg text-center">
        <p className="font-semibold">DATA KATEGORI ORDER TNKB</p>
      </div>
      <div className="mt-2 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-12">
        <div className="sm:col-start-1 sm:col-span-3">
          <Select2
            label="Bulan"
            name="bln"
            val={bln}
            set={onchangeBln}
            item={itemBln}
          />
        </div>
        <div className="sm:col-span-2">
          <Select2
            label="Tahun"
            name="thn"
            val={thn}
            set={setThn}
            item={dataThn}
          />
        </div>
      </div>
      <div className="w-full">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default BarChartKategori;
