import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";

import { API_URL_MASTER } from "../../config/index";
import { useNavigate } from "react-router-dom";
import { HiOutlineSave, HiOutlineArrowLeft } from "react-icons/hi";

import InputText from "../../components/Form2/InputText";

import useSWRMutation from "swr/mutation";
import { sendRequestGet, sendRequestPostWithToken } from "../../swr";
import Loading from "../Loading";
import Select from "../../components/Form2/Select";
import useSWR from "swr";

const FormAdd = () => {
  const [poldaKode, setPoldaKode] = useState("");
  const [tahun, setTahun] = useState("");
  const [dataThn, setDataThn] = useState([]);

  const [totalR2, setTotalR2] = useState("");
  const [totalR4, setTotalR4] = useState("");

  const navigate = useNavigate();

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_MASTER}/master/renbut`,
    sendRequestPostWithToken
  );

  const { data, isLoading } = useSWR(
    `${API_URL_MASTER}/master/polda-select`,
    sendRequestGet
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    loadTahun();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const result = await trigger({
        poldaKode: poldaKode,
        tahun: tahun,
        totalR2: totalR2,
        totalR4: totalR4,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  const loadTahun = () => {
    const dataTh = [];
    for (let index = new Date().getFullYear(); index > 2021; index--) {
      dataTh.push({
        name: `${index}`,
        value: index,
      });
    }

    setDataThn(dataTh);
  };

  if (isMutating || isLoading) return <Loading />;

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-sans text-lg font-bold text-indigo-700">
          Tambah Renbut Baru
        </h2>
        <p className="text-sm font-sans text-gray-400">
          Penambahan data renbut, isi informasi data dibawah ini.
        </p>
      </div>
      <div className="grid grid-cols-6 py-6">
        <form
          onSubmit={simpan}
          className="col-start-2 col-span-4 py-5 shadow-xl rounded-2xl bg-white"
        >
          <div className="border-b border-gray-300 pb-2 px-4">
            <h2 className="font-sans font-bold text-lg">Detail Renbut</h2>
            <p className="font-sans font-normal text-sm text-gray-400">
              Polda, tahun, total R2, total R4
            </p>
          </div>
          <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 px-4 sm:grid-cols-6">
            <div className="sm:col-start-1 sm:col-span-4">
              <Select
                label="Polda"
                name="poldaKode"
                set={setPoldaKode}
                val={poldaKode}
                item={data.data}
              />
            </div>
            <div className="sm:col-start-1 sm:col-span-3">
              <Select
                label="Tahun"
                name="thn"
                val={tahun}
                set={setTahun}
                item={dataThn}
              />
              <p className="text-sm font-sans text-gray-500">
                Pilih tahun untuk input data renbut dibawah!
              </p>
            </div>

            <div className="flex gap-2 sm:col-start-1 sm:col-span-6">
              <div className="sm:col-start-1 sm:row-span-4 w-full">
                <InputText
                  label="Total R2"
                  name="totalR2"
                  val={totalR2}
                  set={(value) => {
                    const filteredValue = value.replace(/[^0-9]/g, "");
                    setTotalR2(filteredValue);
                  }}
                  placeholder="Masukkan total R2"
                />
              </div>
              <div className="sm:col-start-1 sm:row-span-4 w-full relative">
                <InputText
                  label="Total R4"
                  name="totalR4"
                  val={totalR4}
                  set={(value) => {
                    const filteredValue = value.replace(/[^0-9]/g, "");
                    setTotalR4(filteredValue);
                  }}
                  placeholder="Masukkan total R4"
                />
              </div>
            </div>
          </div>
          <div className="mt-10 flex items-center gap-x-2 px-4 pt-5 border-t">
            <button
              type="button"
              className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              onClick={() => navigate(-1)}
            >
              <span className="mr-2">
                <HiOutlineArrowLeft size={18} />
              </span>
              <span>Cancel</span>
            </button>
            <button
              type="submit"
              disabled={isMutating}
              className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            >
              <span className="mr-2">
                <HiOutlineSave size={18} />
              </span>
              <span>Simpan Renbut</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormAdd;
