import React from "react";
import ReactApexChart from "react-apexcharts";

const BarChartIconPerbulan = ({ config }) => {
  return (
    <>
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="bar"
        height={100}
        width={100}
      />
    </>
  );
};

export default BarChartIconPerbulan;
