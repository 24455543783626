import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const SyncOrder = () => {
  return (
    <div>
      <Header
        titleHead="Sync Order"
        icon="solar:server-square-update-bold-duotone"
      />
      <Outlet />
    </div>
  );
};

export default SyncOrder;
