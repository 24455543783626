import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const DashboardRenbut = () => {
  return (
    <div>
      <Header
        titleHead="Dashboard Renbut TNKB"
        icon="solar:graph-new-up-bold"
      />
      <Outlet />
    </div>
  );
};

export default DashboardRenbut;
