import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const Renbut = () => {
  return (
    <div>
      <Header titleHead="Renbut" icon="solar:graph-up-bold" />
      <Outlet />
    </div>
  );
};

export default Renbut;
