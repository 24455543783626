import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";

import { API_URL_MASTER, API_URL_WORKSHOP } from "../../config/index";
import { useNavigate } from "react-router-dom";
import { HiOutlinePrinter, HiOutlineArrowLeft } from "react-icons/hi";

import useSWRMutation from "swr/mutation";
import { sendRequestGet, sendRequestPostWithTokenPdf } from "../../swr";
import Loading from "../Loading";
import Select from "../../components/Form2/Select";
import Datepicker from "react-tailwindcss-datepicker";
import useSWR from "swr";
import InputText from "../../components/Form2/InputText";
import Swal from "sweetalert2";

const LapTnkbWorkshop = () => {
  const [poldaKode, setPoldaKode] = useState("");
  const [namaPolda, setNamaPolda] = useState("");

  const [samsatKode, setSamsatKode] = useState("");
  const [namaSamsat, setNamaSamsat] = useState("");

  const [roda, setRoda] = useState("");
  const [namaUser, setNamaUser] = useState("");

  const navigate = useNavigate();

  const [tglMulai, setTglMulai] = useState("");
  const [tglAkhir, setTglAkhir] = useState("");
  const [tglFilter, setTglFilter] = useState({
    startDate: null,
    endDate: null,
  });

  const itemJenisKendaraan = [
    {
      name: "R2/3",
      value: "R2",
    },
    {
      name: "R4/LEBIH",
      value: "R4",
    },
  ];

  const {
    trigger,
    isMutating,
    isLoading: isLoadingDownload,
  } = useSWRMutation(
    `${API_URL_WORKSHOP}/laporan-data-tnkb-admin`,
    sendRequestPostWithTokenPdf
  );

  const { data: dataPolda, isLoading } = useSWR(
    `${API_URL_MASTER}/master/polda-select`,
    sendRequestGet
  );

  const { data: dataSamsat } = useSWR(
    `${API_URL_MASTER}/master/samsat-select?poldaKode=${poldaKode}`,
    sendRequestGet
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const handlePoldaChange = (value) => {
    setPoldaKode(value);

    const selectedPolda = dataPolda.data.find(
      (polda) => polda.value === Number(value)
    );

    setNamaPolda(selectedPolda ? selectedPolda.name : "");
  };

  const handleSamsatChange = (value) => {
    setSamsatKode(value);

    const selectedSamsat = dataSamsat.data.find(
      (samsat) => samsat.value === value
    );

    setNamaSamsat(selectedSamsat ? selectedSamsat.name : "");
  };

  const cetakLap = async (e) => {
    e.preventDefault();

    if (tglFilter.startDate !== null && tglFilter.endDate !== null) {
      setTglMulai(new Date(tglFilter.startDate).getTime());
      setTglAkhir(new Date(tglFilter.endDate).getTime());
    }

    const loadingSwal = Swal.fire({
      title: "Sedang memproses...",
      text: "Silakan tunggu, laporan sedang diproses...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      showConfirmButton: false,
    });

    try {
      const response = await trigger({
        tglMulai: tglMulai,
        tglAkhir: tglAkhir,
        roda: roda,
        kodePolda: poldaKode,
        namaPolda: namaPolda,
        kodeSamsat: samsatKode,
        namaSamsat: namaSamsat,
        namaUser: namaUser,
      });

      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `laporan-${namaSamsat}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      loadingSwal.close();

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: "Laporan berhasil dicetak.",
        confirmButtonText: "Oke",
      });
    } catch (e) {
      console.error(e);

      loadingSwal.close();
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Terjadi kesalahan, silakan coba lagi!",
        confirmButtonText: "Oke",
      });
    }
  };

  if (isLoadingDownload || isLoading) return <Loading />;

  return (
    <div className="mx-10">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-5">
        <div className="sm:col-span-3">
          <div className="sticky top-28">
            <div>
              <h2 className="font-sans text-lg font-bold text-indigo-700">
                Cetak Laporan TNKB Workshop
              </h2>
              <p className="text-sm font-sans text-gray-400">
                Pilih sesuai filter untuk mencetak laporan pemohon!
              </p>
            </div>
            <form onSubmit={cetakLap}>
              <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <div className="block font-sans text-sm leading-6 text-black">
                    Tanggal
                  </div>
                  <Datepicker
                    inputClassName="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 w-full p-2 font-sans"
                    separator="s/d"
                    primaryColor={"orange"}
                    showShortcuts={true}
                    showFooter={true}
                    value={tglFilter}
                    onChange={(newValue) => setTglFilter(newValue)}
                    popoverDirection="down"
                  />
                </div>
                <div className="sm:col-span-3">
                  <Select
                    label="Polda"
                    name="poldaKode"
                    set={handlePoldaChange}
                    val={poldaKode}
                    item={dataPolda?.data || []}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Select
                    label="Samsat"
                    name="samsatKode"
                    set={handleSamsatChange}
                    val={samsatKode}
                    item={dataSamsat?.data || []}
                  />
                </div>
                <div className="sm:col-span-3">
                  <Select
                    label="Roda"
                    name="roda"
                    set={setRoda}
                    val={roda}
                    item={itemJenisKendaraan}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputText
                    label="Nama User"
                    name="namaUser"
                    val={namaUser}
                    set={(value) => setNamaUser(value.toUpperCase())}
                    placeholder="Masukkan nama user"
                  />
                </div>
              </div>
              <div className="mt-10 flex items-center gap-x-2">
                <button
                  type="button"
                  className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                  onClick={() => navigate(-1)}
                >
                  <span className="mr-2">
                    <HiOutlineArrowLeft size={18} />
                  </span>
                  <span>Cancel</span>
                </button>
                <button
                  type="submit"
                  disabled={isMutating}
                  className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                >
                  <span className="mr-2">
                    <HiOutlinePrinter size={18} />
                  </span>
                  <span>Cetak Laporan</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LapTnkbWorkshop;
