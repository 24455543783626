import React from "react";

const SelectFilter = ({ label, name, set, val, fRename, item, nameFilter }) => {
  return (
    <>
      <label
        htmlFor={name}
        className="block font-sans text-sm leading-6 text-black"
      >
        {label}
      </label>
      <div className="mt-1">
        <select
          id={name}
          name={name}
          value={val}
          onChange={(e) =>
            set({
              name: nameFilter,
              val: e.target.value,
              valRename: fRename({
                dataRename: item,
                valRename: e.target.value,
              }),
            })
          }
          className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-100 focus:bg-gray-50 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 font-sans"
        >
          <option value="">- Pilih Semua -</option>
          {item &&
            item.map((item, index) => {
              return (
                <option key={`${name}${index}`} value={item.value}>
                  {item.name}
                </option>
              );
            })}
        </select>
      </div>
    </>
  );
};

export default SelectFilter;
