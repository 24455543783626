import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL_AUTH } from "../../config/index";
import { Icon } from "@iconify-icon/react";
import useSWRMutation from "swr/mutation";
import { sendRequestPostWithToken } from "../../swr";
import Loading from "../Loading";
import InputPassword from "../../components/Form/InputPassword";
import InputText from "../../components/Form2/InputText";
import { HiOutlineSave } from "react-icons/hi";

const Table = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isPassword, setIsPassword] = useState(false);

  const [token, setToken] = useState("");
  const [message, setMessage] = useState("");

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_AUTH}/user/login-token-api`,
    sendRequestPostWithToken
  );

  useEffect(() => {
    // get refresh token
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const result = await trigger({
        username: username,
        password: password,
      });

      setToken(result.token);
      setMessage(result.message);

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate();
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  const handlePassword = () => {
    if (isPassword) {
      setIsPassword(false);
    } else {
      setIsPassword(true);
    }
  };

  if (isMutating) return <Loading />;

  return (
    <>
      <div className="mx-10">
        <div>
          <h2 className="font-sans font-bold text-lg text-indigo-700">
            Generate Token
          </h2>
          <p className="text-sm font-sans text-gray-400">
            Generate Token, isi informasi data dibawah ini.
          </p>
        </div>
        <div className="grid grid-cols-6 py-6">
          <form
            onSubmit={simpan}
            className="col-start-2 col-span-4 py-5 shadow-xl rounded-2xl bg-white"
          >
            <div className="border-b border-gray-300 pb-2 px-4">
              <h2 className="font-sans font-bold text-lg">
                Form Generate Token
              </h2>
              <p className="font-sans font-normal text-sm text-gray-400">
                Username, password
              </p>
            </div>
            <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 px-4 sm:grid-cols-6">
              <div className="sm:col-start-1 sm:col-span-2">
                <InputText
                  label="Username"
                  name="username"
                  val={username}
                  set={setUsername}
                  placeholder="Masukkan username"
                />
              </div>
              <div className="sm:col-start-3 sm:col-span-2 w-full relative">
                <InputPassword
                  type={isPassword ? "text" : "password"}
                  label="Password"
                  name="password"
                  set={setPassword}
                  val={password}
                />
                <span
                  className="absolute bottom-3 end-3"
                  onClick={() => handlePassword()}
                >
                  {isPassword ? <Icon /> : <Icon />}
                </span>
              </div>
            </div>
            <div className="px-4">
              <div className="bg-orange-50 p-4 rounded-lg text-center text-sm font-poppins-regular mt-3">
                <p>Message</p>
                <p>{message}</p>
              </div>
              <div className="bg-emerald-50 p-4 rounded-lg text-center text-sm font-poppins-regular mt-3">
                <p>Token</p>
                <p class="text-sm font-mono border rounded-lg p-4 break-all">
                  {token}
                </p>
              </div>
            </div>
            <div className="mt-10 flex items-center gap-x-2 px-4 pt-5 border-t">
              <button
                type="submit"
                disabled={isMutating}
                className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              >
                <span className="mr-2">
                  <HiOutlineSave size={18} />
                </span>
                <span>Generate Token</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Table;
