export const sendRequestPost = async (url, { arg }) => {
  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(arg),
  });

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

export const sendRequestPostWithToken = async (url, { arg }) => {
  const token = localStorage.getItem("token");
  const res = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(arg),
  });

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

export const sendRequestPostWithTokenPdf = async (url, { arg }) => {
  const token = localStorage.getItem("token");

  if (!token) {
    throw new Error("Token is missing. Please log in again.");
  }

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json, application/pdf", // Tambahkan tipe PDF
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(arg),
    });

    if (!res.ok) {
      const error = new Error("An error occurred while fetching the data.");
      error.info = await res.json(); // Ambil informasi kesalahan dari respons
      error.status = res.status; // Simpan status
      throw error; // Lempar kesalahan
    }

    // Periksa tipe konten dari respons
    const contentType = res.headers.get("Content-Type");
    if (contentType && contentType.includes("application/pdf")) {
      // Jika respons adalah PDF, kembalikan sebagai blob
      return await res.blob(); // Kembalikan blob untuk PDF
    }

    // Jika bukan PDF, kembalikan sebagai JSON
    return await res.json(); // Kembalikan data JSON
  } catch (error) {
    console.error("Fetch error:", error); // Log kesalahan untuk debugging
    throw new Error("An error occurred while fetching the data."); // Lempar kesalahan umum
  }
};

export const sendRequesPostImageWithToken = async (url, { arg }) => {
  const token = localStorage.getItem("token");
  const res = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: arg,
  });

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

export const sendRequestPut = async (url, { arg }) => {
  const token = localStorage.getItem("token");
  const res = await fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(arg),
  });

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

export const sendRequestDelete = async (url, { arg }) => {
  const token = localStorage.getItem("token");

  const res = await fetch(`${url}/${arg.id}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

export const sendRequestGet = async (url) => {
  const token = localStorage.getItem("token");

  const res = await fetch(`${url}`, {
    method: "GET",
    headers: new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.json();
};

export const sendRequestGetDownload = async (url) => {
  const token = localStorage.getItem("token");

  const res = await fetch(`${url}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }

  return res.blob();
};
