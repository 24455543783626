// fungsi ini jalan
// import { useState } from "react";
// import { Outlet } from "react-router-dom";
// import Sidebar from "../components/Sidebar";
// import Header from "../components/Header";

// const Root = () => {
//   const [isCollapsed, setIsCollapsed] = useState(false);

//   // Fungsi untuk toggle sidebar
//   const toggleSidebar = () => {
//     setIsCollapsed((prev) => !prev);
//   };

//   return (
//     <div className="w-full min-h-screen bg-white flex flex-row">
//       <Sidebar isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
//       <section className="flex-auto bg-white mt-28 mb-10">
//         <div className={`relative ${isCollapsed ? "ml-16" : "ml-64"}`}>
//           <Header isCollapsed={isCollapsed} />
//           <Outlet />
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Root;

import { useState } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { setCollapseSidebar, getCollapseSidebar } from "../function/Storage";

const Root = () => {
  const [isCollapsed, setIsCollapsed] = useState(getCollapseSidebar);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    setCollapseSidebar(!isCollapsed);
  };

  return (
    <div className="w-full min-h-screen bg-white flex flex-row">
      <Sidebar
        isCollapsed={isCollapsed} // Pastikan prop isCollapsed dikirim ke Sidebar
        toggleSidebar={toggleSidebar}
      />
      <section className="flex-auto bg-white mt-28 mb-10">
        <div className={`relative ${isCollapsed ? "ml-16" : "ml-64"}`}>
          {/* <Header isCollapsed={isCollapsed} /> */}
          <Outlet
            context={{
              menuCollapse: isCollapsed,
            }}
          />
        </div>
      </section>
    </div>
  );
};

export default Root;
