export const API_URL_AUTH = "https://service-auth.octapp.net"; //auth
export const API_URL_MASTER = "https://service-master.octapp.net"; //master
export const API_URL_OFFICE = "https://service-office.octapp.net"; //office
export const API_URL_WORKSHOP = "https://service-workshop.octapp.net"; //office
export const API_UPLOAD = "https://storage.jurus.id";

// export const API_URL_AUTH = "http://192.168.21.98:7652"; //auth
// export const API_URL_MASTER = "http://192.168.21.98:7651"; //master
// export const API_URL_OFFICE = "http://192.168.21.98:7654"; //office
// export const API_UPLOAD = "https://storage.jurus.id";
