import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const GenerateToken = () => {
  return (
    <div>
      <Header
        titleHead="Generate Token"
        icon="solar:refresh-square-bold-duotone"
      />
      <Outlet />
    </div>
  );
};

export default GenerateToken;
