import React from "react";

const BadgeNopol = (props) => {
  let bgBadge = "bg-emerald-100 text-emerald-600";
  if (props.tipe === "hitam") {
    bgBadge = "bg-slate-950 text-slate-50";
  } else if (props.tipe === "merah") {
    bgBadge = "bg-rose-700 text-rose-50";
  } else if (props.tipe === "kuning") {
    bgBadge = "bg-yellow-500 text-yellow-50";
  } else if (props.tipe === "putih") {
    bgBadge = "bg-slate-50 text-slate-950";
  } else if (props.tipe === "hijau") {
    bgBadge = "bg-emerald-500 text-emerald-50";
  }

  return (
    <span
      className={`pt-1 pl-2 pr-2 pb-1 font-sans text-[10px] rounded-md ${bgBadge}`}
    >
      {props.text}
    </span>
  );
};

export default BadgeNopol;
