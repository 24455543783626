import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL_OFFICE } from "../../config/index";
import useSWRMutation from "swr/mutation";
import { sendRequestPostWithToken } from "../../swr";
import Loading from "../Loading";
import Datepicker from "react-tailwindcss-datepicker";
import Select from "../../components/Form2/Select";
import { HiOutlineSave } from "react-icons/hi";

const Table = () => {
  const navigate = useNavigate();

  const [jenisReport, setJenisReport] = useState("");
  const [tglMulai, setTglMulai] = useState("");
  const [tglAkhir, setTglAkhir] = useState("");
  const [tglFilter, setTglFilter] = useState({
    startDate: null,
    endDate: null,
  });

  const [message, setMessage] = useState("");

  const itemJenisReport = [
    {
      name: "SAMSAT HARIAN",
      value: "samsat_harian",
    },
    {
      name: "KATEGORI HARIAN",
      value: "kategori_harian",
    },
    {
      name: "WARNA HARIAN",
      value: "warna_harian",
    },
    {
      name: "KATEGORI BULANAN",
      value: "kategori_bulanan",
    },
  ];

  const { trigger, isMutating } = useSWRMutation(
    `${API_URL_OFFICE}/report/reset-report`,
    sendRequestPostWithToken
  );

  useEffect(() => {
    // get refresh token
    checkToken();

    setTglMulai(new Date(tglFilter.startDate).getTime());
    setTglAkhir(new Date(tglFilter.endDate).getTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tglFilter]);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const resetReport = async (e) => {
    e.preventDefault();

    try {
      const result = await trigger({
        jenisReport: jenisReport,
        tglMulai: tglMulai,
        tglAkhir: tglAkhir,
      });

      setMessage(result.message);

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: result.message,
        confirmButtonText: "Oke",
        willClose: () => {
          navigate();
        },
      });
    } catch (e) {
      if (e.status > 400) {
        Swal.fire("Error!", e.info.message, "error");
      } else {
        Swal.fire("Error!", "Error, Silahkan ulangi kembali!", "error");
      }
    }
  };

  if (isMutating) return <Loading />;

  return (
    <>
      <div className="mx-10">
        <div>
          <h2 className="font-sans font-bold text-lg text-indigo-700">
            Reset Total Report
          </h2>
          <p className="text-sm font-sans text-gray-400">
            Reset Total, isi informasi data dibawah ini.
          </p>
        </div>
        <div className="grid grid-cols-6 py-6">
          <form
            onSubmit={resetReport}
            className="col-start-2 col-span-4 py-5 shadow-xl rounded-2xl bg-white"
          >
            <div className="border-b border-gray-300 pb-2 px-4">
              <h2 className="font-sans font-bold text-lg">
                Form Reset Total Report
              </h2>
              <p className="font-sans font-normal text-sm text-gray-400">
                Jenis report, tanggal
              </p>
            </div>
            <div className="px-4">
              <div
                class="mt-2 bg-yellow-100 border border-yellow-200 text-sm text-yellow-800 rounded-lg p-4 dark:bg-yellow-800/10 dark:border-yellow-900 dark:text-yellow-500"
                role="alert"
                tabindex="-1"
                aria-labelledby="hs-soft-color-warning-label"
              >
                <span id="hs-soft-color-warning-label" class="font-bold">
                  Warning!
                </span>{" "}
                jika filter berdasarkan bulan, pilih dari tanggal awal s/d
                tanggal akhir!
              </div>
            </div>
            <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 px-4 sm:grid-cols-6">
              <div className="sm:col-start-1 sm:col-span-3">
                <Select
                  label="Jenis Report"
                  name="jenisReport"
                  val={jenisReport}
                  set={setJenisReport}
                  item={itemJenisReport}
                />
              </div>
              <div className="sm:col-start-1 sm:col-span-3">
                <Datepicker
                  inputClassName="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 w-full p-2 font-sans"
                  separator="s/d"
                  primaryColor={"orange"}
                  showShortcuts={true}
                  showFooter={true}
                  value={tglFilter}
                  onChange={(newValue) => setTglFilter(newValue)}
                  popoverDirection="down"
                />
              </div>
            </div>
            <div className="px-4">
              <div className="bg-orange-50 p-4 rounded-lg text-center text-sm font-poppins-regular mt-3">
                <p>Message</p>
                <p>{message}</p>
              </div>
            </div>

            <div className="mt-10 flex items-center gap-x-2 px-4 pt-5 border-t">
              <button
                type="submit"
                disabled={isMutating}
                className="text-white bg-[#1d4ed8] hover:bg-[#1d4ed8]/90 active::bg-[#1d4ed8]/50  focus:outline-none focus:ring-[#1d4ed8]/50 font-sans rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              >
                <span className="mr-2">
                  <HiOutlineSave size={18} />
                </span>
                <span>Reset Total Report</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Table;
