import React, { useState } from "react";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import { API_URL_OFFICE } from "../../config/index.js";
import useSWR from "swr";
import { sendRequestGet } from "../../swr/index.js";
import BarChartIconPerbulan from "./Chart/BarChartIconPerbulan.js";
import Loading from "../Loading/index.js";

const TotalRoda = () => {
  const [nowR2, setNowR2] = useState(0);
  const [lastR2, setLastR2] = useState(0);

  const [nowR4, setNowR4] = useState(0);
  const [lastR4, setLastR4] = useState(0);

  const [last, setLast] = useState(0);

  const [grapR2, setGrapR2] = useState({
    series: [
      {
        name: "Total",
        color: "#fff",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: "320px",
        fontFamily: "Inter, sans-serif",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          borderRadiusApplication: "end",
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        style: {
          fontFamily: "Inter, sans-serif",
        },
      },
      states: {
        hover: {
          filter: {
            type: "darken",
            value: 1,
          },
        },
      },
      stroke: {
        show: true,
        width: 0,
        colors: ["transparent"],
      },
      grid: {
        show: false,
        strokeDashArray: 4,
        padding: {
          left: 2,
          right: 2,
          top: -14,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        floating: false,
        labels: {
          show: false,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  const [grapR4, setGrapR4] = useState({
    series: [
      {
        name: "Total",
        color: "#fff",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: "320px",
        fontFamily: "Inter, sans-serif",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          borderRadiusApplication: "end",
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        style: {
          fontFamily: "Inter, sans-serif",
        },
      },
      states: {
        hover: {
          filter: {
            type: "darken",
            value: 1,
          },
        },
      },
      stroke: {
        show: true,
        width: 0,
        colors: ["transparent"],
      },
      grid: {
        show: false,
        strokeDashArray: 4,
        padding: {
          left: 2,
          right: 2,
          top: -14,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        floating: false,
        labels: {
          show: false,
          style: {
            fontFamily: "Inter, sans-serif",
            cssClass: "text-xs font-normal fill-gray-500 dark:fill-gray-400",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  const { isLoading } = useSWR(
    `${API_URL_OFFICE}/report/total-5bulan`,
    sendRequestGet,
    {
      onSuccess: (data) => {
        // totalR2
        const grR2 = [];
        for (const dtR2 of data.data.dataR2) {
          grR2.push({
            x: dtR2.blnText,
            y: dtR2.total,
          });
        }

        grapR2.series[0].data = grR2;

        const bulan = new Date(Date.now()).getMonth() + 1;
        const bulanLalu = bulan - 1;

        const resultR2 = data.data.dataR2.find(
          (item) => parseInt(item.bln) === bulan
        );

        const resultR2Last = data.data.dataR2.find(
          (item) => parseInt(item.bln) === bulanLalu
        );

        setNowR2(resultR2.total);
        setLastR2(resultR2Last.total);

        // totalR4
        const grR4 = [];
        for (const dtR4 of data.data.dataR4) {
          grR4.push({
            x: dtR4.blnText,
            y: dtR4.total,
          });
        }

        grapR4.series[0].data = grR4;

        const resultR4 = data.data.dataR4.find(
          (item) => parseInt(item.bln) === bulan
        );

        const resultR4Last = data.data.dataR4.find(
          (item) => parseInt(item.bln) === bulanLalu
        );

        setNowR4(resultR4.total);
        setLastR4(resultR4Last.total);

        setLast(data.lastUpdate);
      },
    }
  );

  if (isLoading) return <Loading />;

  return (
    <>
      <div className="flex gap-2 justify-between">
        <div className="font-bold text-lg text-black mb-2">ANALYTICS</div>
        <div>
          <p className="text-red-500">{last}</p>
        </div>
      </div>
      <div className="flex flex-wrap gap-2">
        <div className="flex flex-col bg-gradient-to-r from-violet-400 to-violet-600 shadow rounded-lg p-4 flex-grow md:w-[32%]">
          <span className="font-poppins-regular text-lg font-semibold text-white">
            Total R2/R3
          </span>

          <div className="flex items-center justify-between">
            <span className="text-3xl font-bold text-white">
              {new Intl.NumberFormat("id-ID").format(nowR2)}
            </span>
            <div className="h-16">
              <BarChartIconPerbulan config={grapR2} />
            </div>
          </div>

          <div className="flex items-center text-white">
            <div className="text-blue-500">
              {nowR2 > lastR2 ? (
                <RiArrowUpSFill
                  style={{ fontSize: "30px", color: "#65a30d" }}
                />
              ) : (
                <RiArrowDownSFill
                  style={{ fontSize: "30px", color: "#dc2626" }}
                />
              )}
            </div>
            <span className="text-sm font-semibold">
              {new Intl.NumberFormat("id-ID").format(nowR2 - lastR2)}
            </span>
            &nbsp;
            <span className="text-sm">1 Bulan Terakhir</span>
          </div>
        </div>

        <div className="flex flex-col bg-gradient-to-r from-orange-400 to-orange-600 shadow rounded-lg p-4 flex-grow md:w-[32%]">
          <span className="font-poppins-regular text-lg font-semibold text-white">
            Total R4/LEBIH
          </span>

          <div className="flex items-center justify-between">
            <span className="text-3xl font-bold text-white">
              {new Intl.NumberFormat("id-ID").format(nowR4)}
            </span>
            <div className="h-16">
              <BarChartIconPerbulan config={grapR4} />
            </div>
          </div>

          <div className="flex items-center text-white">
            <div className="text-blue-500">
              {nowR4 > lastR4 ? (
                <RiArrowUpSFill
                  style={{ fontSize: "30px", color: "#65a30d" }}
                />
              ) : (
                <RiArrowDownSFill
                  style={{ fontSize: "30px", color: "#dc2626" }}
                />
              )}
            </div>
            <span className="text-sm font-semibold">
              {new Intl.NumberFormat("id-ID").format(nowR4 - lastR4)}
            </span>
            &nbsp;
            <span className="text-sm">1 Bulan Terakhir</span>
          </div>
        </div>

        <div className="flex flex-col bg-gradient-to-r from-green-400 to-green-600 shadow rounded-lg p-4 flex-grow md:w-[32%] text-white">
          <span className="font-poppins-regular text-lg font-semibold">
            Total Seluruh
          </span>

          <div className="flex items-center justify-between">
            <span className="text-3xl font-bold">
              {new Intl.NumberFormat("id-ID").format(nowR2 + nowR4)}
            </span>
            <div className="h-16">
              {/* <BarChartIconPerbulan config={grapR2} /> */}
            </div>
          </div>

          <div className="flex items-center">
            <div className="text-blue-500">
              {nowR2 + nowR4 > lastR2 + lastR4 ? (
                <RiArrowUpSFill
                  style={{ fontSize: "30px", color: "#65a30d" }}
                />
              ) : (
                <RiArrowDownSFill
                  style={{ fontSize: "30px", color: "#dc2626" }}
                />
              )}
            </div>
            <span className="text-sm font-semibold">
              {new Intl.NumberFormat("id-ID").format(
                nowR2 + nowR4 - (lastR2 + lastR4)
              )}
            </span>
            &nbsp;
            <span className="text-sm">1 Bulan Terakhir</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TotalRoda;
