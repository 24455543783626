import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const Samsat = () => {
  return (
    <div>
      <Header titleHead="Samsat" icon="solar:home-bold-duotone" />
      <Outlet />
    </div>
  );
};

export default Samsat;
