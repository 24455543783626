import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

const ResetTotalReport = () => {
  return (
    <div>
      <Header
        titleHead="Reset Total Report"
        icon="solar:smartphone-update-bold-duotone"
      />
      <Outlet />
    </div>
  );
};

export default ResetTotalReport;
